import React, { useState, useEffect } from "react";
import {
  HtmlEditor,
  Toolbar,
  RichTextEditorComponent,
  Inject,
} from "@syncfusion/ej2-react-richtexteditor";
import axios from "axios";

const GeneralTrouble = ({ showAlert, GeneralData, user, ToSaveGeneralData }) => {

  const toolbarSettings = {
    items: [
      "Bold",
      "Italic",
      "Underline",
      "StrikeThrough",
      "FontName",
      "FontSize",
      "FontColor",
      "BackgroundColor",
      "LowerCase",
      "UpperCase",
      "|",
      "Formats",
      "Alignments",
      "OrderedList",
      "UnorderedList",
      "Outdent",
      "Indent",
      "|",
      "CreateLink",
      "Image",
      "|",
      "ClearFormat",
      "Print",
      "SourceCode",
      "FullScreen",
      "|",
      "Undo",
      "Redo",
    ],
  }

  const [content, setContent] = useState("");

  const GetRTEData = (e) => {

    // console.log(e)

    if (e.target) {
      showAlert.current = true;
      setContent(e.target.value);
    } else {
      showAlert.current = true;
      setContent(e.value);
    }
  };

  useEffect(() => {

    GeneralData.current = content;

  }, [content]);

  const fetchContent = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/mold_session/get_General_content_by_mold/${user.id}`
      );

      if (!response.data.error) {
        setContent(
          response.data.content === "null" ? "" : response.data.content
        );
        ToSaveGeneralData.current = false;
      }

    } catch (error) {
      // console.error("Error fetching content:", error);
    }
  };

  useEffect(() => {

    if (user) {
      fetchContent();
    } else {
    }
  }, [user]);

  return (
    <div>
      <div className="TabCardTitle">
        <span> General Trouble Shooting Notes</span>
      </div>
      <div>
        <RichTextEditorComponent
          toolbarSettings={toolbarSettings}
          height={"60vh"}
          width={"85vw"}
          value={content}
          change={GetRTEData}
        >
          <Inject services={[Toolbar, HtmlEditor]} />
        </RichTextEditorComponent>
      </div>
    </div>
  );
};

export default GeneralTrouble;
