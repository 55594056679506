import React from "react";

import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject,
  LineSeries,
  RangeColumnSeries,
  ScatterSeries,
  Legend,
  DateTime,
  Tooltip,
  Highlight,
} from "@syncfusion/ej2-react-charts";

const sanitizeId = (id) => {
  return id.replace(/[^a-zA-Z0-9-_]/g, "_");
};

let x_min = null;
let x_max = null;

function calculateNiceNumbers(min, max, numTicks) {
  const range = max - min;
  const roughStep = range / numTicks;
  const magnitude = Math.floor(Math.log10(roughStep));
  const magnitudePow = Math.pow(10, magnitude);
  const mantissa = roughStep / magnitudePow;
  let niceStep;

  if (mantissa <= 1.0) {
    niceStep = 1 * magnitudePow;
  } else if (mantissa <= 2.0) {
    niceStep = 2.5 * magnitudePow;
  } else if (mantissa <= 5.0) {
    niceStep = 5 * magnitudePow;
  } else {
    niceStep = 6 * magnitudePow;
  }

  const niceMin = Math.floor(min / niceStep) * niceStep;
  const niceMax = Math.ceil(max / niceStep) * niceStep;

  const numNiceTicks = Math.floor((niceMax - niceMin) / niceStep) + 1;
  const niceTicks = Array.from(
    { length: numNiceTicks },
    (_, i) => niceMin + i * niceStep
  );

  return {
    min: niceMin,
    max: niceMax,
    step: niceStep,
    ticks: niceTicks,
  };
}

const SuggestedDimChart = ({
  title,
  result,
  rangeColumnData,
  scatterData,
  x_axis,
  GoldSpot,
}) => {
  const sanitizedId = sanitizeId(`charts_${title}`);
  if (x_axis !== undefined) {
    x_min = x_axis[0] - 1;
    x_max = x_axis[1] + 1;
  }
  console.log(rangeColumnData)

  const nicenumData = [];

  if (result && Array.isArray(rangeColumnData) && rangeColumnData.length > 0) {
    result.forEach((data) => {
      nicenumData.push(data.nom);
      nicenumData.push(data.usl);
      nicenumData.push(data.lsl);
    });
  }

  if (
    rangeColumnData &&
    Array.isArray(rangeColumnData) &&
    rangeColumnData.length > 0
  ) {
    rangeColumnData.forEach((data) => {
      nicenumData.push(data.high);
      nicenumData.push(data.low);
    });
  }

  let calNiceNum = { min: 0, max: 0, ticks: [] };

  if (rangeColumnData && result) {
    const minChartDataValue = Math.min(...nicenumData);

    const maxChartDataValue = Math.max(...nicenumData);

    const noOfTicks = 5;

    calNiceNum = calculateNiceNumbers(
      parseFloat(minChartDataValue),
      parseFloat(maxChartDataValue),
      noOfTicks
    );
  }

  const axisLabelRender = (args) => {
    if (args.axis.orientation === "Horizontal") {
      if (!x_axis.includes(args.value)) {
        args.value = "";
      }
    }
  };

  function calculateColumnWidthAndSpacing(binCount) {
    let columnWidth;
    let spacing;

    // Assuming you want to keep the columns at 75% of the bin width
    if (binCount >= 16) {
      columnWidth = 0.75;
    } else if (binCount >= 8 && binCount < 16) {
      columnWidth = 0.65;
    } else if (binCount >= 4 && binCount < 8) {
      columnWidth = 0.55;
    } else if (binCount >= 1 && binCount < 4) {
      columnWidth = 0.45;
    }
    spacing = 1 - columnWidth;

    return {
      columnWidth: columnWidth,
      spacing: spacing,
    };
  }

  let columnWidth, spacing;
  if (rangeColumnData) {
    const binCount = rangeColumnData.length; // or any other number of bins
    ({ columnWidth, spacing } = calculateColumnWidthAndSpacing(binCount));
  }

  return (
    <>
      {x_axis && rangeColumnData && result && (
        <ChartComponent
          id={sanitizedId}
          style={{ textAlign: "left" }}
          title={title}
          width="100%"
          height="300"
          border={{ width: 1, color: "darkblue" }}
          primaryXAxis={{
            maximum: x_max,
            minimum: x_min,
            interval: 1,
            valueType: "Double",
            edgeLabelPlacement: "Shift",
            majorGridLines: { width: 0 },
            lineStyle: { color: "black" },
          }}
          primaryYAxis={{
            maximum: parseFloat(calNiceNum.max),
            minimum: parseFloat(calNiceNum.min),
            interval: parseFloat(calNiceNum.step),
            majorTickLines: { width: 0 },
            minorTickLines: { width: 0 },
            lineStyle: { color: "black" },
          }}
          axisLabelRender={axisLabelRender.bind(this)}
          chartArea={{ border: { width: 0 } }}
          tooltip={{ enable: true }}
          legendSettings={{ enableHighlight: true }}
        >
          <Inject
            services={[
              LineSeries,
              RangeColumnSeries,
              ScatterSeries,
              DateTime,
              Legend,
              Tooltip,
              Highlight,
            ]}
          />

          <SeriesCollectionDirective>
            <SeriesDirective
              dataSource={result}
              xName="X"
              yName="usl"
              width={2}
              type="Line"
              fill="red"
              animation={{ enable: false }}
            ></SeriesDirective>
            <SeriesDirective
              dataSource={result}
              xName="X"
              yName="nom"
              width={2}
              type="Line"
              fill="green"
              animation={{ enable: false }}
            ></SeriesDirective>
            <SeriesDirective
              dataSource={result}
              xName="X"
              yName="lsl"
              width={2}
              type="Line"
              fill="red"
              animation={{ enable: false }}
            ></SeriesDirective>
            <SeriesDirective
              dataSource={rangeColumnData}
              xName="x"
              high="high"
              low="low"
              type="RangeColumn"
              fill="#99CCFF"
              opacity={1}
              columnWidth={columnWidth}
              animation={{ enable: false }}
              columnSpacing={spacing}
            ></SeriesDirective>
            <SeriesDirective
              dataSource={scatterData}
              xName="x"
              yName="y"
              fill="black"
              type="Scatter"
              animation={{ enable: false }}
              marker={{
                visible: true,
                width: 7,
                height: 7,
                shape: "Cross",
                // isFilled: true,
              }}
              width={2}
            ></SeriesDirective>
          </SeriesCollectionDirective>
        </ChartComponent>
      )}
    </>
  );
};

export default SuggestedDimChart;
