import React, { useState, useRef } from "react";
import "../../assets/custom-stylesheet/app2_style.css";
import "../../assets/custom-stylesheet/samplepage_style.css";
import "../App.css";
import "../../assets/custom-stylesheet/grid_stylecss.css";
import {
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
} from "@syncfusion/ej2-react-navigations";
// import Conversions from "../conversions/Conversions";
import DefectSolution from "./Defect/DefectSolution";
import ProcessParameter from "./ProcessParameter/ProcessParameter";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import { Button } from "reactstrap";

const MoldTroubleshoot = () => {
  
  const history = useHistory();

  var { moldId } = useParams();

  const [Mold_Id, setMold_Id] = useState();

  let headertext = [
    { text: "Defect-solution" },
    { text: "Process Parameter Effect" },
  ];

  function content0() {
    return (
      <div className="card p-3 ml-2 b-dark">
        <DefectSolution
          moldId={moldId}
          setMold_Id={setMold_Id}
          showAlert={showAlert}
          Mold_Id={Mold_Id}
          DefectData={DefectData}
          ToSaveDefectData={ToSaveDefectData}
        />
      </div>
    );
  }

  function content1() {
    return (
      <div className="card p-3 ml-2 b-dark">
        <ProcessParameter showAlert={showAlert} Mold_Id={Mold_Id} ProcessData={ProcessData} ToSaveProcessData={ToSaveProcessData} />
      </div>
    )
  }

  const showAlert = useRef(false);
  const GoToMolds = useRef(false);

  // Boolean variable to switch between the save and update button
  const ToSaveDefectData = useRef(true);
  const ToSaveProcessData = useRef(true);

  const DefectData = useRef([]);
  const ProcessData = useRef([]);

  // This function will be called when the user clicks on the save button of the study
  const SavedModal = () => {
    saveData().then(() => {
      const saveddialogBox = document.getElementById("SaveddialogBox");

      saveddialogBox.classList.remove("hidden");
    });
  };

  // Instance of dialog box Asking for saving data before leaving study
  const dialogBox = document.getElementById("dialogBox");

  // Instance of dialog box which will be shown after saving
  const SavedandExitdialogBox = document.getElementById(
    "SavedandExitdialogBox"
  );

  // Event to get back to Session's
  const BackToMold = () => {
    if (showAlert.current) {
      dialogBox.classList.remove("hidden");
    } else {
      if (GoToMolds.current) {
        history.push("/dashboard");
      } else {
        history.push(`/mold`);
      }
    }
  };

  const BackToDashboard = () => {
    if (showAlert.current) {
      dialogBox.classList.remove("hidden");
      GoToMolds.current = true;
    } else {
      history.push("/dashboard");
    }
  };

  // Event to close the saving confirmation dialog
  const closeDialog = () => {
    const dialogBox = document.getElementById("dialogBox");

    dialogBox.classList.add("hidden");
  };

  // Event to finally close and exit study after saving
  const closeandExitSavedDialog = () => {
    const saveddialogBox = document.getElementById("SavedandExitdialogBox");

    saveddialogBox.classList.add("hidden");

    if (GoToMolds.current) {
      history.push("/dashboard");
    } else {
      history.push(`/mold`);
    }
  };

  // Event to close the dialog which will be shown after saving
  const closeSavedDialog = () => {
    const saveddialogBox = document.getElementById("SaveddialogBox");

    saveddialogBox.classList.add("hidden");
  };

  const SaveandExit = () => {
    saveData().then(() => {
      closeDialog();

      SavedandExitdialogBox.classList.remove("hidden");
    });
  };

  // Convert content to a Blob and trigger download
  const saveAsBlob = async () => {
    const blob = new Blob([DefectData.current], {
      type: "text/html",
    }); // Replace with your RTE content

    const formData = new FormData();
    formData.append("mold", Mold_Id);
    formData.append("content", blob, "rte-content.html"); // Append blob to the form data

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/mold_session/upload-blob/`,
        {
          method: "POST",
          body: formData,
        }
      );

      ToSaveDefectData.current = false;

      showAlert.current = false
    } catch (error) {
      // console.error("Error uploading blob:", error);
    }
  };

  // Convert content to a Blob and trigger download
  const saveProcessDataAsBlob = async () => {
    const blob = new Blob([ProcessData.current], {
      type: "text/html",
    }); // Replace with your RTE content

    const formData = new FormData();
    formData.append("mold", Mold_Id);
    formData.append("content", blob, "rte-content.html"); // Append blob to the form data

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/mold_session/upload_ProcessTransfer_blob/`,
        {
          method: "POST",
          body: formData,
        }
      );

      ToSaveProcessData.current = false;

      showAlert.current = false
    } catch (error) {
      // console.error("Error uploading blob:", error);
    }
  };

  // Convert content to a Blob and trigger download
  const UpdateBlob = async () => {
    const blob = new Blob([DefectData.current], {
      type: "text/html",
    }); // Replace with your RTE content

    const formData = new FormData();
    formData.append("content", blob, "rte-content.html"); // Append blob to the form data

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/mold_session/update_content_by_mold/${Mold_Id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      showAlert.current = false

      // console.log(response);
    } catch (error) {
      // console.error("Error uploading blob:", error);
    }
  };

  // Convert content to a Blob and trigger download
  const UpdateProcessDataBlob = async () => {
    
    const blob = new Blob([ProcessData.current], {
      type: "text/html",
    }); // Replace with your RTE content

    const formData = new FormData();
    formData.append("content", blob, "rte-content.html"); // Append blob to the form data

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/mold_session/update_ProcessTransfer_content_by_mold/${Mold_Id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      showAlert.current = false

      // console.log(response);
    } catch (error) {
      // console.error("Error uploading blob:", error);
    }
  };

  // Event to Call the POST request API and save the data
  const saveData = () => {
    return new Promise((resolve, reject) => {
      if (ToSaveDefectData.current) {

        saveAsBlob();
      } else {

        UpdateBlob();
      }

      if (ToSaveProcessData.current) {
        // console.log(ScrewData.current)

        saveProcessDataAsBlob();
      } else {

        // console.log(ToSaveProcessData.current)

        UpdateProcessDataBlob();
      }

      resolve();
    });
  };

  return (
    <>
      {/* To alert while going back to sessions page */}
      <div id="dialogBox" className="hidden">
        <h5> Nautilus </h5>
        <hr></hr>
        <div className="dialog-content">
          <p> Do you want to save Mold Trouble Shoot Data ? </p>
          <Button className="mr-1" id="closeDialogBtn" onClick={SaveandExit}>
            {" "}
            Yes{" "}
          </Button>
          <Button
            className="mr-1"
            id="closeDialogBtn"
            onClick={closeandExitSavedDialog}
          >
            {" "}
            No{" "}
          </Button>
          <Button className="mr-1" id="closeDialogBtn" onClick={closeDialog}>
            {" "}
            Cancel{" "}
          </Button>
        </div>
      </div>

      {/* To show when data is saved and when clicked on close button exit */}
      <div id="SavedandExitdialogBox" className="hidden">
        <h5> Nautilus </h5>
        <hr></hr>
        <div className="dialog-content">
          <p> Saved Successfully. </p>
          <Button
            className="mr-1"
            id="closeDialogBtn"
            onClick={closeandExitSavedDialog}
          >
            {" "}
            Close{" "}
          </Button>
        </div>
      </div>

      {/* To show when data is saved, when clicked on saved button */}
      <div id="SaveddialogBox" className="hidden">
        <h5> Nautilus </h5>
        <hr></hr>
        <div className="dialog-content">
          <p> Saved Successfully. </p>
          <Button
            className="mr-1"
            id="closeDialogBtn"
            onClick={closeSavedDialog}
          >
            {" "}
            Close{" "}
          </Button>
        </div>
      </div>

      <div className="container-fluid">
        
        <div className="d-flex justify-content-between ml-3 pt-3 pb-3">
          <div className="d-flex">
            <div>
              <span
                className="BreadCrum"
                onClick={BackToDashboard}
                style={{ fontSize: "14px", color: "blue" }}
              >
                {" "}
                Dashboard{" "}
              </span>
            </div>

            <div>
              <span className="BreadCrum" style={{ fontSize: "16px" }}>
                {" "}
                {">"}{" "}
              </span>
            </div>
            <div>
              <span
                className="BreadCrum"
                onClick={BackToMold}
                style={{ fontSize: "14px", color: "blue" }}
              >
                {" "}
                Mold{" "}
              </span>
            </div>
            <div>
              <span className="BreadCrum" style={{ fontSize: "16px" }}>
                {" "}
                {">"}{" "}
              </span>
            </div>
            <div>
              <span style={{ fontSize: "14px" }}>Mold Trouble Shooting </span>
            </div>
          </div>
        </div>

        <div>
          <div className="d-flex">
            <div>
              <TabComponent heightAdjustMode="Auto" id="defaultTab">
                <TabItemsDirective>
                  <TabItemDirective header={headertext[0]} content={content0} />
                  <TabItemDirective header={headertext[1]} content={content1} />
                </TabItemsDirective>
              </TabComponent>
            </div>

            <div>
              <button
                className="btn btn-primary btn-air-primary m-2"
                type="button"
                onClick={SavedModal}
              >
                Save
              </button>
            </div>
          </div>
        </div>
        
      </div>
    </>
  );
};

export default MoldTroubleshoot;
