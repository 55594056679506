import React from "react";
import { Table } from "react-bootstrap";

const ConfidenceRatingGrid = ({
  ResponseName,
  Specifications,
  RangeColumnData,
}) => {
  const organizeData = () => {
    const cavData = {};
    const dimensions = new Set();

    ResponseName.forEach(([name], index) => {
      const parts = name.split("dimension~")[1].split("-");
      const cav = `${parts[0]}-${parts[1]}`;
      const dimension = parts.slice(2).join("-");

      if (!cavData[cav]) {
        cavData[cav] = {};
      }

      // Remove the line that uses PredictedValues
      // cavData[cav][dimension] = Number(PredictedValues[index]).toFixed(4);
      dimensions.add(dimension);
    });

    return { cavData, dimensions: Array.from(dimensions) };
  };

  const { cavData, dimensions } = organizeData();
  const cavities = Object.keys(cavData);

  const checkRangeColumnData = (rangeColumnData, spec) => {
    // console.log("rangecolumndata",rangeColumnData)
    let results = [];

    for (const entry of rangeColumnData) {
      const { title, high, low } = entry;
      // console.log("title",title)
      const matchingKey = Object.keys(spec).find(key => key.startsWith(title));

      if (!matchingKey) continue;

      const { lsl, usl } = spec[matchingKey] || {};
      // console.log(spec)
      // console.log("spec",spec[title])
     

      if (lsl !== undefined && usl !== undefined) {
        // Check the classification
        const highWithin = high >= lsl && high <= usl;
        const lowWithin = low >= lsl && low <= usl;

        const lslBetweenHighLow = lsl >= low && lsl <= high;
        const uslBetweenHighLow = usl >= low && usl <= high;

        if (highWithin && lowWithin) {
          results.push(`A`);
        } else if (
          highWithin ||
          lowWithin ||
          lslBetweenHighLow ||
          uslBetweenHighLow
        ) {
          results.push(`B`);
        } else if (high > usl || low < lsl) {
          results.push(`C`);
        }
      }
    }
    // console.log("results",results)
    return results;
  };

  // Run the function for each group in RangeColumnData and log the result
  let cpk_prediction = [];
  // console.log(Specifications)
  // console.log("RangeColumnData",RangeColumnData)
  if (Specifications !== undefined) {
    RangeColumnData.forEach((group, index) => {
      let predicted_cpk = checkRangeColumnData(group, Specifications);

      cpk_prediction.push(predicted_cpk);
    });
  }

  // console.log(RangeColumnData);
  return (
    <>
      <div
        className="spreadsheet"
        id="BackPress-Calc-Grid"
        style={{ width: "100%", height: "auto" }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div>
                  <div
                    className="Cavity-Grid-Container"
                    style={{ border: "1px solid #573DAC" }}
                  >
                    <div style={{ width: "1000px" }}>
                      {Specifications && (
                        <Table
                          striped
                          bordered
                          hover
                          responsive
                          variant="light"
                        >
                          <thead>
                            <tr>
                              <th
                                // rowSpan="2"
                                style={{
                                  whiteSpace: "nowrap",
                                  padding: "0 8px",
                                  maxWidth: "1px",
                                }}
                              >
                                Specification
                              </th>
                              {dimensions.map((dimension, key) => (
                                <th
                                  // colSpan={cavities.length}
                                  key={key}
                                >
                                  {dimension}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {["usl", "plus_tol", "nom", "minus_tol", "lsl"].map(
                              (specKey, key) => (
                                <tr key={key}>
                                  <td>
                                    {specKey === "plus_tol"
                                      ? "+ TOL"
                                      : specKey === "minus_tol"
                                      ? "- TOL"
                                      : specKey.replace("_", " ").toUpperCase()}
                                  </td>
                                  {dimensions.map((dimension) => (
                                    <td
                                      // colSpan={cavities.length}
                                      key={dimension}
                                    >
                                      {Specifications[dimension]?.[
                                        specKey
                                      ]?.toFixed(3) || "-"}
                                    </td>
                                  ))}
                                </tr>
                              )
                            )}
                          </tbody>

                          {/* <tbody>
                            <tr>
                              <td>Predicted CpK</td>
                              <td colSpan={dimensions.length}></td>
                            </tr>
                          </tbody> */}

                          <tbody>
                            {cavities.map((cav, rowIndex) => (
                              <tr key={cav}>
                                <td>{cav}</td>
                                {dimensions.map((dimension, colIndex) => (
                                  <td
                                    key={dimension}
                                    style={{
                                      color:
                                        cpk_prediction[colIndex][rowIndex] ===
                                        "A"
                                          ? "#155724"
                                          : cpk_prediction[colIndex][
                                              rowIndex
                                            ] === "B"
                                          ? "#0c5460"
                                          : cpk_prediction[colIndex][
                                              rowIndex
                                            ] === "C"
                                          ? "#721c24"
                                          : "black", // Fallback color
                                      backgroundColor:
                                        cpk_prediction[colIndex][rowIndex] ===
                                        "A"
                                          ? "#d4edda"
                                          : cpk_prediction[colIndex][
                                              rowIndex
                                            ] === "B"
                                          ? "#d1ecf1"
                                          : cpk_prediction[colIndex][
                                              rowIndex
                                            ] === "C"
                                          ? "#f8d7da"
                                          : "inherit", // Fallback color
                                    }}
                                  >
                                    {cpk_prediction[colIndex][rowIndex]}
                                  </td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfidenceRatingGrid;
