import React from 'react'

const Captcha = ({captcha, userInput, setUserInput, refreshCaptcha }) => {
    return (
        <div style={{ textAlign: "left", margin: "10px" }}>
            <div
                className="mr-1"
                style={{
                    display: "inline-block",
                    backgroundColor: "#ddd",
                    padding: "10px",
                    fontSize: "24px",
                    fontWeight: "bold",
                    letterSpacing: "3px",
                    fontFamily: "monospace",
                    textDecoration: "line-through",
                }}
            >
                {captcha}
            </div>
            <input
                type="text"
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
                placeholder="Enter CAPTCHA"
                required
            />
            <button onClick={refreshCaptcha} style={{ margin: "10px" }}>🔄 Refresh</button>
        </div>
    )
}

export default Captcha