import React, { useEffect } from "react";
import {
  GridComponent,
  Inject,
  Page,
  Freeze,
  ColumnDirective,
  ColumnsDirective,
} from "@syncfusion/ej2-react-grids";

const ProcessMachineGrid = ({
  MachineDataList,
  SelectedMachine,
  ProcessRefMachineData,
  ProcessTgtMachineData,
  PopulateMMGrid, SelectedMold
}) => {
  var PMGrid;

  let Suitable_Machines = [];

  useEffect(() => {
    // console.log(MachineDataList);
    // console.log(ProcessTgtMachineData);

    if (ProcessTgtMachineData["Shot Size"]) {
      Suitable_Machines = machinesWithAllTrue(ProcessTgtMachineData);
    }

    sessionStorage.setItem(
      "Suitable_Machines",
      JSON.stringify(Suitable_Machines)
    );
  }, [ProcessTgtMachineData]);

  function machinesWithAllTrue(data) {
    const machineResults = {};

    // Initialize machine results tracking
    for (const category in data) {
      data[category].forEach((entry) => {
        // If the machine hasn't been encountered yet, initialize it as true
        if (!(entry.Machine in machineResults)) {
          machineResults[entry.Machine] = true;
        }

        // If the result is not true or is null, mark the machine as false
        if (!("result" in entry) || entry.result == null) {
        } else if (!entry.result) {
          machineResults[entry.Machine] = false;
        }
      });
    }

    // Filter machines that have all `result` as true across all categories
    const allTrueMachines = Object.keys(machineResults).filter(
      (machine) => machineResults[machine]
    );

    // console.log(allTrueMachines);

    return allTrueMachines;
  }

  const MoldParamTemplate = (props) => {
    // console.log(props);
    return (
      <div>
        <span style={{ fontWeight: "bold" }}>
          {" "}
          {props["Molding Parameter"]}{" "}
        </span>
      </div>
    );
  };

  const RefMachValueTemplate = (props) => {
    let IsNA = props["value"] && props["value"] !== "NA" ? props["value"] : "*";
    return (
      <div>
        <span
          style={{
            fontWeight: "bold",
            fontSize: IsNA === "*" ? "20px" : null,
            color: IsNA === "*" ? "red" : null,
          }}
        >
          {" "}
          {props["value"] && props["value"] !== "NA" && !isNaN(props["value"])
            ? props["value"]
            : "*"}{" "}
        </span>
      </div>
    );
  };

  const RefMachUnitTemplate = (props) => {
    return (
      <div>
        <span style={{ fontWeight: "bold" }}> {props["unit"]} </span>
      </div>
    );
  };

  //   **********************************************

  const MachineValueDataTemplate = (props, idx) => {
    let IsNA = ProcessTgtMachineData[props["Molding Parameter"]]
      ? ProcessTgtMachineData[props["Molding Parameter"]].length > 0 &&
        ProcessTgtMachineData[props["Molding Parameter"]][idx]["value"] !==
        "" &&
        !isNaN(ProcessTgtMachineData[props["Molding Parameter"]][idx]["value"])
        ? ProcessTgtMachineData[props["Molding Parameter"]][idx]["value"] ===
          null
          ? "*"
          : parseFloat(
            ProcessTgtMachineData[props["Molding Parameter"]][idx]["value"]
          ).toFixed(2)
        : isNaN(parseFloat(props["value"]).toFixed(2))
          ? "*"
          : parseFloat(props["value"]).toFixed(2)
      : "*";

    let ConditonMet =
      IsNA === "*"
        ? false
        : ProcessTgtMachineData[props["Molding Parameter"]]
          ? ProcessTgtMachineData[props["Molding Parameter"]].length > 0
            ? "result" in ProcessTgtMachineData[props["Molding Parameter"]][idx]
              ? ProcessTgtMachineData[props["Molding Parameter"]][idx]["result"]
              : null
            : null
          : null;

    return (
      <div>
        <span
          style={{
            fontWeight: "bold",
            fontSize: IsNA === "*" ? "20px" : null,
            color:
              IsNA === "*"
                ? "red"
                : ConditonMet !== null
                  ? ConditonMet
                    ? "green"
                    : "red"
                  : null,
          }}
        >
          {ProcessTgtMachineData[props["Molding Parameter"]]
            ? ProcessTgtMachineData[props["Molding Parameter"]].length > 0 &&
              ProcessTgtMachineData[props["Molding Parameter"]][idx][
              "value"
              ] !== "" &&
              !isNaN(
                ProcessTgtMachineData[props["Molding Parameter"]][idx]["value"]
              )
              ? ProcessTgtMachineData[props["Molding Parameter"]][idx][
                "value"
              ] === null
                ? "*"
                : parseFloat(
                  ProcessTgtMachineData[props["Molding Parameter"]][idx][
                  "value"
                  ]
                ).toFixed(2)
              : isNaN(parseFloat(props["value"]).toFixed(2))
                ? "*"
                : parseFloat(props["value"]).toFixed(2)
            : "*"}
        </span>
      </div>
    );
  };

  const MachineUnitDataTemplate = (props, idx) => {
    // console.log(props["unit"])
    return (
      <div>
        <span style={{ fontWeight: "bold" }}>
          {ProcessTgtMachineData[props["Molding Parameter"]]
            ? ProcessTgtMachineData[props["Molding Parameter"]].length > 0 &&
              ProcessTgtMachineData[props["Molding Parameter"]][idx][
              "unit_id"
              ] !== ""
              ? ProcessTgtMachineData[props["Molding Parameter"]][idx][
              "unit_id"
              ]
              : props["unit"]
            : ""}
        </span>
      </div>
    );
  };

  return (
    <>
      {SelectedMold.length > 0 ? (
        <div
          className="MMTable b-primary b-r-4 p-3 ml-2"
          style={{ border: "1px grey solid" }}
        >
          <div className="d-flex">
            <div>
              <span className="mr-1" style={{ color: "red", fontSize: "20px" }}>
                {" "}
                *{" "}
              </span>
            </div>{" "}
            <div>
              <span style={{ color: "red" }}>
                {" "}
                Indicates data for that field is not available.{" "}
              </span>
            </div>
          </div>

          <GridComponent
            id="PMGrid"
            ref={(g) => (PMGrid = g)}
            dataSource={PopulateMMGrid ? ProcessRefMachineData : []}
            allowPaging={true}
            pageSettings={{ pageSize: 50 }}
            frozenColumns={3}
            width={
              MachineDataList.length * 12 < 100
                ? "100%"
                : MachineDataList.length * 12 === 0
                  ? "20%"
                  : `${MachineDataList.length * 12}%`
            }
            height={600}
          >
            <ColumnsDirective>
              <ColumnDirective
                columns={[
                  {
                    field: "Molding Parameter",
                    headerText: "",
                    textAlign: "left",
                    width: 200,
                    minWidth: 10,
                    template: MoldParamTemplate,
                  },
                ]}
                headerText="Molding Parameter"
                textAlign="center"
              />

              <ColumnDirective
                columns={[
                  {
                    field: "value",
                    headerText: "Value",
                    textAlign: "center",
                    width: 80,
                    minWidth: 10,
                    template: RefMachValueTemplate,
                  },
                  {
                    field: "unit",
                    headerText: "Units",
                    textAlign: "center",
                    width: 80,
                    minWidth: 10,
                    template: RefMachUnitTemplate,
                  },
                ]}
                headerTemplate={() => {
                  return (
                    <span>
                      {SelectedMachine ? SelectedMachine.Machine_Number : ""}
                    </span>
                  );
                }}
                textAlign="center"
                width={100}
              />

              {MachineDataList.map((ColumnData, key) =>
                ColumnData.Machine_Number === SelectedMachine.Machine_Number ? (
                  <React.Fragment key={key}></React.Fragment>
                ) : (
                  <ColumnDirective
                    key={key}
                    columns={[
                      {
                        field: "value",
                        headerText: "Process",
                        textAlign: "center",
                        width: 90,
                        minWidth: 10,
                        template: (props) =>
                          MachineValueDataTemplate(props, key),
                      },
                      {
                        field: "unit",
                        headerText: "Units",
                        textAlign: "center",
                        width: 90,
                        minWidth: 10,
                        template: (props) =>
                          MachineUnitDataTemplate(props, key),
                      },
                    ]}
                    headerTemplate={() => {
                      return (
                        <div
                          style={{
                            backgroundColor:
                              Suitable_Machines.length > 0 &&
                                Suitable_Machines.some(
                                  (Machine) =>
                                    Machine === ColumnData.Machine_Number
                                )
                                ? "green"
                                : "red",
                            color: "#fff",
                          }}
                        >
                          <span>
                            {ColumnData ? ColumnData.Machine_Number : ""}
                          </span>
                        </div>
                      );
                    }}
                    textAlign="center"
                  />
                )
              )}
            </ColumnsDirective>

            <Inject services={[Page, Freeze]} />
          </GridComponent>
        </div>
      ) : (
        <div
          style={{
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "1.5rem",
          }}
        >
          <span>
            Select the Mold and Process Sheet session to view the Compatibility
            here.
          </span>
        </div>
      )}
    </>
  );
};

export default ProcessMachineGrid;
