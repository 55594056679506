import React from "react";
import NumericalChartComponent from "./ChartComponent";

const NumericalPrediction = ({
  title,
  Result,
  RangeColumnData,
  ScatterData,
  x_axis,
  ShowGoldSpot,
  GoldSpot,
  ShowMaxDim,
  MaxDim
}) => {
  const filterOutHighLow = (rangeData = [], scatterData = [], goldSpotData = [], maxDimData = []) => {
    // Ensure rangeData is an array before filtering
    const filteredRange = rangeData.filter(item => item.high !== "-" && item.low !== "-");

    // Filter scatterData based on the remaining filteredRange (matching x values)
    const filteredScatter = scatterData.filter(scatterItem =>
      filteredRange.some(rangeItem => scatterItem.x === rangeItem.x)
    );

    // Filter GoldSpot based on the remaining filteredRange (matching x values)
    const filteredGoldSpot = goldSpotData.filter(goldSpotItem =>
      filteredRange.some(rangeItem => goldSpotItem.x === rangeItem.x)
    );

    // Filter MaxDim based on the remaining filteredRange (matching x values)
    const filteredMaxDim = maxDimData.filter(maxDimItem =>
      filteredRange.some(rangeItem => maxDimItem.x === rangeItem.x)
    );

    return { filteredRange, filteredScatter, filteredGoldSpot, filteredMaxDim };
  };

  // console.log(RangeColumnData)
  // console.log(title)

  return (
    <>
      {title.map((item, index) => {
        // Safely check if data exists before passing it to the filtering function
        const currentRangeColumnData = RangeColumnData[index] || [];
        const currentScatterData = ScatterData[index] || [];
        const currentGoldSpot = GoldSpot[index] || [];
        const currentMaxDim = MaxDim[index] || [];

        const { filteredRange, filteredScatter, filteredGoldSpot, filteredMaxDim } = filterOutHighLow(
          currentRangeColumnData,
          currentScatterData,
          currentGoldSpot,
          currentMaxDim
        );

        

        if (item === "-") {
          return null; // Skip this iteration
        }
       

        return (
          
          <NumericalChartComponent
            width={"100%"}
            key={index}
            title={item}
            result={Result[index]}
            rangeColumnData={filteredRange}  // Filtered range data
            scatterData={filteredScatter}    // Filtered scatter data
            x_axis={x_axis}
            ShowGoldSpot={ShowGoldSpot}
            GoldSpot={filteredGoldSpot}      // Filtered GoldSpot
            ShowMaxDim={ShowMaxDim}
            MaxDim={filteredMaxDim}          // Filtered MaxDim
          />
        );
      })}
    </>
  );
};

export default NumericalPrediction;

// import React from "react";
// import NumericalChartComponent from "./ChartComponent";

// const NumericalPrediction = ({
//   title,
//   Result,
//   RangeColumnData,
//   ScatterData,
//   x_axis,
//   ShowGoldSpot,
//   GoldSpot,
//   ShowMaxDim,
//   MaxDim
// }) => {
//   const filterOutHighLow = (rangeData = [], scatterData = [], goldSpotData = [], maxDimData = []) => {
//     // Filter out range data where either high or low is "-"
//     const filteredRange = rangeData.filter(item => item.high !== "-" && item.low !== "-");

//     // Filter scatterData based on the filtered range (matching x values)
//     const filteredScatter = scatterData.filter(scatterItem =>
//       filteredRange.some(rangeItem => scatterItem.x === rangeItem.x)
//     );

//     // Filter GoldSpot data based on the filtered range
//     const filteredGoldSpot = goldSpotData.filter(goldSpotItem =>
//       filteredRange.some(rangeItem => goldSpotItem.x === rangeItem.x)
//     );

//     // Filter MaxDim data based on the filtered range
//     const filteredMaxDim = maxDimData.filter(maxDimItem =>
//       filteredRange.some(rangeItem => maxDimItem.x === rangeItem.x)
//     );

//     return { filteredRange, filteredScatter, filteredGoldSpot, filteredMaxDim };
//   };

//   return (
//     <>
//       {title.map((item, index) => {
//         // Safely check if data exists before passing it to the filtering function
//         const currentRangeColumnData = RangeColumnData[index] || [];
//         const currentScatterData = ScatterData[index] || [];
//         const currentGoldSpot = GoldSpot[index] || [];
//         const currentMaxDim = MaxDim[index] || [];

//         // Apply filtering logic
//         const { filteredRange, filteredScatter, filteredGoldSpot, filteredMaxDim } = filterOutHighLow(
//           currentRangeColumnData,
//           currentScatterData,
//           currentGoldSpot,
//           currentMaxDim
//         );

//         // If the filtered range is empty, don't render the chart for this title
//         if (filteredRange.length === 0) {
//           return null;
//         }

//         return (
//           <NumericalChartComponent
//             width={"100%"}
//             key={index}
//             title={item}
//             result={Result[index]}
//             rangeColumnData={filteredRange}  // Pass only filtered range data
//             scatterData={filteredScatter}    // Pass only filtered scatter data
//             x_axis={x_axis}
//             ShowGoldSpot={ShowGoldSpot}
//             GoldSpot={filteredGoldSpot}      // Pass only filtered GoldSpot data
//             ShowMaxDim={ShowMaxDim}
//             MaxDim={filteredMaxDim}          // Pass only filtered MaxDim data
//           />
//         );
//       })}
//     </>
//   );
// };

// export default NumericalPrediction;
