import React, { useState, useEffect } from "react";
import "../../App.css";
import { useParams } from "react-router-dom";
import TableComponent from "../Analysis/TableComponent";
import DataService from "../../../services/ApiService";

function ExperimentalRunSettings() {
  const { sessionId } = useParams();

  const [fetchedData, setFetchedData] = useState({
    variables: [],
  });

  const [expRunsData, setExpRunsData] = useState();

  useEffect(() => {
    const decry = atob(sessionId);

    DataService.ExptData(decry)
      .then((res) => {
        // console.log("exptdata API Response", res);
        setFetchedData(res.data);
      })
      .catch((err) => {
        console.log("exptdata err", err);
      });

    DataService.FactorialTable(decry)
      .then((res) => {
        const runsJson = res.data["runsJson"];
        // console.log("FactorialTable API Response", res);

        const dataArray = Array.isArray(runsJson) ? runsJson : [runsJson];

        setExpRunsData(dataArray);
      })
      .catch((err) => {
        console.log("FactorialTable error", err);
      });
  }, [sessionId]);

  if (!fetchedData.variables) {
    return <div>Loading data...</div>;
  }

  const dataArr = JSON.parse(expRunsData?.[0] || "[]");

  let runsValue = 1;

  const dataArrWithRuns = dataArr.map((item) => {
    const newItem = {
      Runs: runsValue,
      ...item,
    };
    runsValue++; // Increment runsValue by 1 for each entry
    return newItem;
  });

  // Get reverse order dynamically
  // Keep "runs" first, reverse the rest
  // console.log(dataArrWithRuns)

  // const transformedData = dataArrWithRuns.map((item) => {
  //   const { Runs, ...otherFields } = item; // Extract "Runs" separately
  //   const reversedOtherFields = Object.fromEntries(
  //     Object.entries(otherFields).reverse()
  //   ); // Reverse other keys
  //   return { Runs: Runs, ...reversedOtherFields }; // Combine "runs" with reversed fields
  // });

  // console.log("dataArrWithRuns", dataArrWithRuns);
  return (
    <div className="m-2 b-primary">
      <TableComponent data={dataArrWithRuns} />
    </div>
  );
}

export default ExperimentalRunSettings;
