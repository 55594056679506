import React, { useState, useRef, useEffect } from "react";
import {
  GridComponent,
  Inject,
  Page,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import { useHistory, useParams } from "react-router-dom";
import DataService from "../../services/ApiService";
import Loader from "../../assets/Loader";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

const ManageSessions = () => {
  const history = useHistory();

  var { moldId } = useParams();

  const [Mold_Id, setMold_Id] = useState();

  var grid;

  const toolbar = ["Search"];

  const selectionSettings = { type: "Single" };

  const [ModalStates, setModalStates] = useState({
    SelectSession: {
      visibility: false,
      message: "",
    },
    EditModal: {
      visibility: false,
    },
    DeleteModal: {
      visibility: false,
    },
    SavedModal: {
      visibility: false,
    },
    DeleteComplete: {
      visibility: false,
    },
  });

  const ToggleModalStates = (ModalKey, operation) => {
    setModalStates({
      ...ModalStates,
      [ModalKey]: {
        message: `Select a session to ${operation}.`,
        visibility: !ModalStates[ModalKey].visibility,
      },
    });
  };

  function created(args) {
    if (grid) {
      document
        .getElementById(grid.element.id + "_searchbar")
        .addEventListener("keyup", (args) => {
          var gridObj =
            document.getElementById("ManageSessionsGrid").ej2_instances[0];
          gridObj.search(args.target.value);
        });
    }
  }

  const showAlert = useRef(false);
  const GoToMolds = useRef(false);

  // Instance of dialog box Asking for saving data before leaving study
  const dialogBox = document.getElementById("dialogBox");

  // Event to get back to Session's
  const BackToMold = () => {
    if (showAlert.current) {
      dialogBox.classList.remove("hidden");
    } else {
      if (GoToMolds.current) {
        history.push("/dashboard");
      } else {
        history.push(`/mold`);
      }
    }
  };

  const BackToDashboard = () => {
    if (showAlert.current) {
      dialogBox.classList.remove("hidden");
      GoToMolds.current = true;
    } else {
      history.push("/dashboard");
    }
  };

  const [SessionData, setSessionData] = useState({
    MeltSessions: [],
    PPSSessions: [],
    SixStepSessions: [],
    QualitySessions: [],
    DOESessions: [],
  });

  const [SelectedSession, setSelectedSession] = useState({
    id: "",
    Activity: "",
  });

  const [NewSession, setNewSession] = useState("");

  const onChangeSessionName = (e) => {
    setNewSession(e.target.value);
  };

  function SelectSession(props) {
    return (
      <input
        type="radio"
        name="session"
        defaultChecked={SelectedSession.index === props.index ? true : false}
        onChange={() => setSelectedSession(props)}
      />
    );
  }

  const OnEdit = () => {
    if (SelectedSession.id === "") {
      ToggleModalStates("SelectSession", "Edit");
    } else {
      ToggleModalStates("EditModal", null);
    }
  };

  const OnDelete = () => {
    if (SelectedSession.id === "") {
      ToggleModalStates("SelectSession", "Delete");
    } else {
      ToggleModalStates("DeleteModal", null);
    }
  };

  const UpdateSession = () => {
    let body = {
      Session_Name:
        NewSession === "" ? SelectedSession.Session_Name : NewSession,
    };

    // console.log( SelectedSession )

    if (SelectedSession.Activity === "6 Step Study") {
      DataService.UpdateSession(SelectedSession.id, body).then((res) => {
        setAllSessionData((prevArray) => {
          let Updateddata = [...prevArray];
          Updateddata[SelectedSession.index] = {
            ...Updateddata[SelectedSession.index],
            Session_Name:
              NewSession === "" ? SelectedSession.Session_Name : NewSession,
          };
          return Updateddata;
        });
        setModalStates({
          ...ModalStates,
          EditModal: {
            visibility: false,
          },
          SavedModal: {
            visibility: true,
          },
        });
      });
    }
    if (SelectedSession.Activity === "Melt Preparation") {
      DataService.UpdateMeltSession(SelectedSession.id, body).then((res) => {
        setAllSessionData((prevArray) => {
          let Updateddata = [...prevArray];
          Updateddata[SelectedSession.index] = {
            ...Updateddata[SelectedSession.index],
            Session_Name:
              NewSession === "" ? SelectedSession.Session_Name : NewSession,
          };
          return Updateddata;
        });
        setModalStates({
          ...ModalStates,
          EditModal: {
            visibility: false,
          },
          SavedModal: {
            visibility: true,
          },
        });
      });
    }
    if (SelectedSession.Activity === "Plastic Process Sheet") {
      DataService.UpdatePPSSession(SelectedSession.id, body).then((res) => {
        setAllSessionData((prevArray) => {
          let Updateddata = [...prevArray];
          Updateddata[SelectedSession.index] = {
            ...Updateddata[SelectedSession.index],
            Session_Name:
              NewSession === "" ? SelectedSession.Session_Name : NewSession,
          };
          return Updateddata;
        });
        setModalStates({
          ...ModalStates,
          EditModal: {
            visibility: false,
          },
          SavedModal: {
            visibility: true,
          },
        });
      });
    }
    if (SelectedSession.Activity === "Quality Data") {
      DataService.UpdateQualitySession(SelectedSession.id, body).then((res) => {
        setAllSessionData((prevArray) => {
          let Updateddata = [...prevArray];
          Updateddata[SelectedSession.index] = {
            ...Updateddata[SelectedSession.index],
            Session_Name:
              NewSession === "" ? SelectedSession.Session_Name : NewSession,
          };
          return Updateddata;
        });
        setModalStates({
          ...ModalStates,
          EditModal: {
            visibility: false,
          },
          SavedModal: {
            visibility: true,
          },
        });
      });
    }
    if (SelectedSession.Activity === "DOE") {
      DataService.UpdateDOESession(SelectedSession.id, body).then((res) => {
        setAllSessionData((prevArray) => {
          let Updateddata = [...prevArray];
          Updateddata[SelectedSession.index] = {
            ...Updateddata[SelectedSession.index],
            Session_Name:
              NewSession === "" ? SelectedSession.Session_Name : NewSession,
          };
          return Updateddata;
        });
        setModalStates({
          ...ModalStates,
          EditModal: {
            visibility: false,
          },
          SavedModal: {
            visibility: true,
          },
        });
      });
    }
  };

  const DeleteSession = () => {
    if (SelectedSession.Activity === "6 Step Study") {
      DataService.DeleteSession(SelectedSession.id).then((res) => {
        setAllSessionData((prevArray) => {
          let Updateddata = prevArray.filter(
            (data) => data.id !== SelectedSession.id
          );
          return Updateddata;
        });

        setModalStates({
          ...ModalStates,
          DeleteModal: {
            visibility: false,
          },
          DeleteComplete: {
            visibility: true,
          },
        });
      });
    }
    if (SelectedSession.Activity === "Melt Preparation") {
      DataService.DeleteMeltSession(SelectedSession.id).then((res) => {

        setAllSessionData((prevArray) => {
          let Updateddata = prevArray.filter(
            (data) => data.id !== SelectedSession.id
          );
          return Updateddata;
        });

        setModalStates({
          ...ModalStates,
          DeleteModal: {
            visibility: false,
          },
          DeleteComplete: {
            visibility: true,
          },
        });
      });
    }
    if (SelectedSession.Activity === "Plastic Process Sheet") {
      DataService.DeletePPSSession(SelectedSession.id).then((res) => {

        setAllSessionData((prevArray) => {
          let Updateddata = prevArray.filter(
            (data) => data.id !== SelectedSession.id
          );
          return Updateddata;
        });

        setModalStates({
          ...ModalStates,
          DeleteModal: {
            visibility: false,
          },
          DeleteComplete: {
            visibility: true,
          },
        });
      });
    }
    if (SelectedSession.Activity === "Quality Data") {
      DataService.DeleteQualitySession(SelectedSession.id).then((res) => {

        setAllSessionData((prevArray) => {
          let Updateddata = prevArray.filter(
            (data) => data.id !== SelectedSession.id
          );
          return Updateddata;
        });

        setModalStates({
          ...ModalStates,
          DeleteModal: {
            visibility: false,
          },
          DeleteComplete: {
            visibility: true,
          },
        });
      });
    }
    if (SelectedSession.Activity === "DOE") {
      DataService.DeleteDOESession(SelectedSession.id).then((res) => {

        setAllSessionData((prevArray) => {
          let Updateddata = prevArray.filter(
            (data) => data.id !== SelectedSession.id
          );
          return Updateddata;
        });

        setModalStates({
          ...ModalStates,
          DeleteModal: {
            visibility: false,
          },
          DeleteComplete: {
            visibility: true,
          },
        });
      });
    }
  };

  const column = [
    {
      field: "Select",
      headerText: "",
      textAlign: "center",
      width: "5%",
      template: SelectSession.bind(this),
    },
    {
      field: "Activity",
      headerText: "Activity",
      textAlign: "center",
      width: "20%",
    },
    {
      field: "Session_Name",
      headerText: "Session Name",
      textAlign: "center",
      width: "20%",
    },
    {
      field: "Created_Date",
      headerText: "Created Date",
      textAlign: "center",
      width: "20%",
    },
    // {
    //   field: "Modified_Date",
    //   headerText: "Modified Date",
    //   textAlign: "center",
    // },
  ];

  const [AllSessionData, setAllSessionData] = useState([]);

  // Event to Call the POST request API and save the data
  const fetchSesions = (Id) => {
    return new Promise((resolve, reject) => {
      if (Id) {
        // Fetch Melt Sessions
        DataService.FetchMeltSession(Id)
          .then((res) => {
            if (!res.data.message) {
              setSessionData((prevData) => ({
                ...prevData,
                MeltSessions: res.data,
              }));
            } else {
              setSessionData((prevData) => ({
                ...prevData,
                MeltSessions: [],
              }));
            }
          })
          .catch((err) => {
            // console.error(err);
          });

        // Fetch Six Step Sessions
        DataService.FetchSession(Id)
          .then((res) => {
            if (!res.data.message) {
              setSessionData((prevData) => ({
                ...prevData,
                SixStepSessions: res.data,
              }));
            } else {
              setSessionData((prevData) => ({
                ...prevData,
                SixStepSessions: [],
              }));
            }
          })
          .catch((err) => {
            // console.error(err);
          });

        DataService.FetchPPSSession(Id)
          .then((res) => {
            if (!res.data.message) {
              setSessionData((prevData) => ({
                ...prevData,
                PPSSessions: res.data,
              }));
            } else {
              setSessionData((prevData) => ({
                ...prevData,
                PPSSessions: [],
              }));
            }
          })
          .catch((err) => {
            // console.error(err);
          });

        DataService.FetchQualitySession(Id)
          .then((res) => {
            if (!res.data.message) {
              setSessionData((prevData) => ({
                ...prevData,
                QualitySessions: res.data,
              }));
            } else {
              setSessionData((prevData) => ({
                ...prevData,
                QualitySessions: [],
              }));
            }
          })
          .catch((err) => {
            // console.error(err);
          });

        DataService.FetchDoeSession(Id)
          .then((res) => {
            if (!res.data.message) {
              setSessionData((prevData) => ({
                ...prevData,
                DOESessions: res.data,
              }));
            } else {
              setSessionData((prevData) => ({
                ...prevData,
                DOESessions: [],
              }));
            }
          })
          .catch((err) => {
            // console.error(err);
          });
        resolve();
      }
    });
  };

  const CreateGridData = () => {
    let TempData = [];
    Object.keys(SessionData).forEach((key) => {
      // console.log(SessionData[key]);
      SessionData[key].forEach((session) => {
        TempData.push({
          id: session.id,
          Activity:
            `${key}` === "SixStepSessions"
              ? "6 Step Study"
              : `${key}` === "MeltSessions"
                ? "Melt Preparation"
                : `${key}` === "PPSSessions"
                  ? "Plastic Process Sheet"
                  : `${key}` === "QualitySessions"
                    ? "Quality Data"
                    : `${key}` === "DOESessions"
                      ? "DOE"
                      : "",
          Session_Name: session.Session_Name,
          Created_Date: session.Date,
        });
      });
    });
    setAllSessionData(TempData);
    // console.log(TempData);
  };

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      CreateGridData();
    }, 1500);

    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }, [SessionData]);

  useEffect(() => {
    setMold_Id(atob(moldId));

    if (Mold_Id) {
      fetchSesions(Mold_Id);
    } else {
    }
  }, [Mold_Id]);

  return (
    <>
      {isLoading ? <Loader /> : <></>}
      <Modal
        isOpen={ModalStates.SelectSession.visibility}
        centered={true}
        style={{ width: "200px" }}
      >
        <ModalHeader> Nautilus </ModalHeader>
        <ModalBody>
          <span> {ModalStates.SelectSession.message} </span>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => ToggleModalStates("SelectSession", null)}
          >
            {" "}
            Close{" "}
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={ModalStates.SavedModal.visibility}
        centered={true}
        style={{ width: "200px" }}
      >
        <ModalHeader> Nautilus </ModalHeader>
        <ModalBody>
          <span> {"Updated Successfully"} </span>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              ToggleModalStates("SavedModal");
            }}
          >
            {" "}
            Close{" "}
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={ModalStates.DeleteComplete.visibility}
        centered={true}
        style={{ width: "200px" }}
      >
        <ModalHeader> Nautilus </ModalHeader>
        <ModalBody>
          <span> {"Deleted Successfully"} </span>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              ToggleModalStates("DeleteComplete");
            }}
          >
            {" "}
            Close{" "}
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={ModalStates.EditModal.visibility}
        centered={true}
        style={{ width: "300px" }}
      >
        <ModalHeader> Nautilus </ModalHeader>
        <ModalBody>
          <span> {SelectedSession.Activity}: </span>
          <input
            className="form-control"
            defaultValue={SelectedSession.Session_Name}
            onChange={onChangeSessionName}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={UpdateSession}>
            {" "}
            Ok{" "}
          </Button>
          <Button
            color="primary"
            onClick={() => ToggleModalStates("EditModal", null)}
          >
            {" "}
            Cancel{" "}
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={ModalStates.DeleteModal.visibility}
        centered={true}
        style={{ width: "300px" }}
      >
        <ModalHeader> Nautilus </ModalHeader>
        <ModalBody>
          <span>
            {" "}
            Do you want to delete,{" "}
            <strong> {SelectedSession.Session_Name} </strong> ?{" "}
          </span>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={DeleteSession}>
            {" "}
            Yes{" "}
          </Button>
          <Button
            color="primary"
            onClick={() => ToggleModalStates("DeleteModal", null)}
          >
            {" "}
            No{" "}
          </Button>
        </ModalFooter>
      </Modal>
      <div className="d-flex justify-content-between ml-3 pt-3 pb-3">
        <div className="d-flex">
          <div>
            <span
              className="BreadCrum"
              onClick={BackToDashboard}
              style={{ fontSize: "14px", color: "blue" }}
            >
              {" "}
              Dashboard{" "}
            </span>
          </div>

          <div>
            <span className="BreadCrum" style={{ fontSize: "16px" }}>
              {" "}
              {">"}{" "}
            </span>
          </div>
          <div>
            <span
              className="BreadCrum"
              onClick={BackToMold}
              style={{ fontSize: "14px", color: "blue" }}
            >
              {" "}
              Mold{" "}
            </span>
          </div>
          <div>
            <span className="BreadCrum" style={{ fontSize: "16px" }}>
              {" "}
              {">"}{" "}
            </span>
          </div>
          <div>
            <span style={{ fontSize: "14px" }}> Manage Sessions </span>
          </div>
        </div>
      </div>

      <div className="ml-4 table-responsive">
        <GridComponent
          width={"50vw"}
          height={"50vh"}
          ref={(g) => (grid = g)}
          id="ManageSessionsGrid"
          toolbar={toolbar}
          dataSource={AllSessionData}
          columns={column}
          selectionSettings={selectionSettings}
          // allowPaging={true}
          // pageSettings={{ pageCount: 20 }}
          created={created.bind(this)}
        >
          <Inject services={[Toolbar]} />
        </GridComponent>
      </div>

      <div className="m-4">
        <button
          className="btn btn-warning btn-air-warning"
          type="button"
          onClick={OnEdit}
        >
          {" "}
          Rename{" "}
        </button>

        <button
          className="btn btn-primary btn-air-primary ml-2"
          type="button"
          onClick={OnDelete}
        >
          {" "}
          Delete{" "}
        </button>
      </div>
    </>
  );
};

export default ManageSessions;
