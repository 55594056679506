import React, { useEffect } from 'react';
import { SpreadsheetComponent, SheetsDirective, SheetDirective, ColumnsDirective, ColumnDirective, focus } from '@syncfusion/ej2-react-spreadsheet';
import { getRangeIndexes } from '@syncfusion/ej2-spreadsheet';
import '../App.css';
import '../../assets/custom-stylesheet/grid_stylecss.css';

const PressureGrid = ({ PressureGridRow, setPressureGridRow, Max_Press_Available, PressureSpreadsheet, PressurerowCount, setRowToBeDeleted, PopulateSheet, showAlert }) => {

  const protectSettings = { selectCells: true };

  const scrollSettings = { isFinite: true };

  const dialogBeforeOpen = (args) => {

    if (args.dialogName === 'EditAlertDialog') {
      args.cancel = true
    }

    // Edit the dialog content using the dialogBeforeOpen event.
    if (args.dialogName === 'ValidationErrorDialog') {
      args.cancel = true
    }

  }

  useEffect(() => {

    // Adding custom function for calculating the percentage between two cells.
    PressureSpreadsheet.current.lockCells('A2:B100', false)

    // eslint-disable-next-line
  }, [])

  const GetValues = () => {

    return new Promise((resolve, reject) => {

      let TempArray = []

      // JavaScript
      const tdElements = document.querySelectorAll('#Pressure_Sheet .e-cell');

      tdElements.forEach(td => {

        const cellValue = td.textContent;

        if (cellValue) {
          TempArray.push(cellValue);
        } else {
          TempArray.push(null);
        }

      });

      resolve(TempArray)

      // console.log(TempArray)

    })

  }

  const ConvertToJson = (DataArray) => {

    return new Promise((resolve, reject) => {

      let TempArray = [];

      let NumberOfRow = 0;

      for (let i = 0; i < Math.ceil(DataArray.length / 5); i++) {

        TempArray.push({

          "Flow_Area": DataArray[0 + NumberOfRow],
          "Peak_Pressure": DataArray[1 + NumberOfRow],
          "Percent_Maximum": '',
          "Delta P": '',
          "%Delta P": '',

        })

        NumberOfRow = NumberOfRow + 5

      }

      resolve(TempArray)

      // console.log(TempArray)

    })

  }

  const PerformCalcs = (TabelObjData) => {

    return new Promise((resolve, reject) => {

      let TempArray = [];

      for (let i = 0; i < TabelObjData.length; i++) {

        if (i === 0) {

          TempArray.push({

            "Flow_Area": TabelObjData[0].Flow_Area,

            "Peak_Pressure": TabelObjData[0].Peak_Pressure,

            "Percent_Maximum": TabelObjData[0]["Peak_Pressure"] ? Number((100 * parseFloat(TabelObjData[i]["Peak_Pressure"])) / Max_Press_Available).toFixed(3) : "",

            "Delta P": TabelObjData[0]["Peak_Pressure"] ? TabelObjData[0]["Peak_Pressure"] : "",

            "%Delta P": TabelObjData[0]["Peak_Pressure"] ? Number((100 * parseFloat(TabelObjData[0]["Peak_Pressure"])) / Max_Press_Available).toFixed(3) : "",

            "Max_Press_Available": Max_Press_Available

          })

        }
        else {

          TempArray.push({

            "Flow_Area": TabelObjData[i].Flow_Area,

            "Peak_Pressure": TabelObjData[i].Peak_Pressure,

            "Percent_Maximum": TabelObjData[i]["Peak_Pressure"] ? Number((100 * parseFloat(TabelObjData[i]["Peak_Pressure"])) / Max_Press_Available).toFixed(3) : "",

            "Delta P": TabelObjData[i]["Peak_Pressure"] ? parseFloat(TabelObjData[i]["Peak_Pressure"]) - parseFloat(TabelObjData[i - 1]["Peak_Pressure"]) : "",

            "%Delta P": TabelObjData[i]["Peak_Pressure"] ? Number(
              (100
                *
                (parseFloat(TabelObjData[i]["Peak_Pressure"]) - parseFloat(TabelObjData[i - 1]["Peak_Pressure"]))
              )
              /
              Max_Press_Available
            ).toFixed(3) : "",

            "Max_Press_Available": Max_Press_Available

          })

        }

      }

      resolve(TempArray)

      // console.log(TempArray)

    })

  }

  const getIndex = () => {

    PressureSpreadsheet.current.lockCells('A2:B100', false)

    // Getting the index of the selected row and storing in a variable
    let cell = PressureSpreadsheet.current.getActiveSheet().activeCell;

    let cellIdx = getRangeIndexes(cell);

    setRowToBeDeleted(cellIdx[0]);

    GetValues().then((DataArray) => {

      ConvertToJson(DataArray).then((TabelObjData) => {

        // console.log(TabelObjData)

        let PeakPressureValues = TabelObjData.filter((value) => parseInt(value.Peak_Pressure) > parseInt(Max_Press_Available))

        // console.log(PeakPressureValues)

        // if (PeakPressureValues.length === 0) {

        PerformCalcs(TabelObjData).then((CalcData) => {

          PopulateSheet(PeakPressureValues.length === 0 ? CalcData : TabelObjData)

          setPressureGridRow(PeakPressureValues.length === 0 ? CalcData : TabelObjData)

          // console.log(CalcData)

        })

        // }

      })

    })

  }

  useEffect(() => {

    if (PressureSpreadsheet.current) { getIndex() }

  }, [Max_Press_Available])

  function onCreated() {

    //Applies data validation to specified range in the active sheet.
    PressureSpreadsheet.current.addDataValidation(
      {
        type: 'Decimal',
        operator: 'Between',
        value1: '-10000.0000',
        value2: '10000.0000',
      },
      `B1:B100`
    );

    PressureSpreadsheet.current.cellFormat({ textAlign: 'left' }, 'A1:A100');

    PopulateSheet(PressureGridRow);
    // console.log(PressureGridRow);

    focus(PressureSpreadsheet.current.element);

  }

  const cellEditing = (args) => {

    if (args.value !== args.oldValue && !isNaN(args.value)) {

      PressureSpreadsheet.current.updateCell({ value: args.value }, args.address);

      showAlert.current = true

      getIndex()

    } else {

      showAlert.current = false

    }

  };

  const UnlockCells = () => {

    PressureSpreadsheet.current.lockCells(
      `A1:B${PressureSpreadsheet.current.getActiveSheet().rowCount}`,
      false
    );

    PressureSpreadsheet.current.getActiveSheet().rowCount = PressurerowCount

    // console.log(PressurerowCount)

  }

  const beforeCellRenderHandler = (args) => {

    // Condition to check whether the rendered element is header cell.
    if (
      args.colIndex === 0 &&
      args.element.classList.contains('e-header-cell')
    ) {
      const text = 'Flow Area'
      args.element.innerText = text;
    }
    if (
      args.colIndex === 1 &&
      args.element.classList.contains('e-header-cell')
    ) {
      const text = 'Peak Pressure'
      args.element.innerText = text;
    }
    if (
      args.colIndex === 2 &&
      args.element.classList.contains('e-header-cell')
    ) {
      const text = '% Maximum'
      args.element.innerText = text;
    }
    if (
      args.colIndex === 3 &&
      args.element.classList.contains('e-header-cell')
    ) {
      const text = 'Delta P'
      args.element.innerText = text;
    }
    if (
      args.colIndex === 4 &&
      args.element.classList.contains('e-header-cell')
    ) {
      const text = '%Delta P'
      args.element.innerText = text;
    }

  }

  function actionBegin(args) {

    if (args.action === 'clipboard' && args.args.eventArgs.requestType === 'paste') {
      //Set the type to 'Values' to paste only the values.
      args.args.eventArgs.type = 'Values';

      showAlert.current = true;
    }

  }

  return (
    <div className="pressure-grid">

      <div className="spreadsheet" id="Pressure_Sheet" onMouseEnter={UnlockCells} onBlur={getIndex}>

        <SpreadsheetComponent
          ref={PressureSpreadsheet}
          onClick={getIndex}
          cellEdited={getIndex}
          cellEditing={cellEditing}
          height={400}
          width={"100%"}
          showFormulaBar={false}
          showSheetTabs={false}
          showRibbon={false}
          dialogBeforeOpen={dialogBeforeOpen.bind(this)}
          scrollSettings={scrollSettings}
          beforeCellRender={beforeCellRenderHandler}
          created={onCreated}
          allowAutoFill={false} enableContextMenu={false} allowImage={false} actionBegin={actionBegin}
        >

          <SheetsDirective>

            <SheetDirective
              // rowCount={PressurerowCount}
              colCount={5}
              isProtected={true}
              protectSettings={protectSettings}
            >

              <ColumnsDirective>
                <ColumnDirective width={100}></ColumnDirective>
                <ColumnDirective width={100}></ColumnDirective>
                <ColumnDirective width={100}></ColumnDirective>
                <ColumnDirective width={100}></ColumnDirective>
                <ColumnDirective width={100}></ColumnDirective>
              </ColumnsDirective>

            </SheetDirective>

          </SheetsDirective>

        </SpreadsheetComponent>

      </div>

    </div>
  )
}

export default PressureGrid
