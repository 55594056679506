import React, { useEffect, useState } from "react";
import DataService from "../../../services/ApiService";

import Chart from "./SensitivityChart";
import { Button } from "reactstrap";

import ExperimentalRunSettings from "../EditExptData/ExperimentalRunSettings";

const SensitivityOutliers = ({ session, mold }) => {
  const [data, setData] = useState({});
  const [selectedIndex, setSelectedIndex] = useState("");
  const [noOfDimensions, setnoOfDimensions] = useState([]);
  const [renderComponent, setRenderComponent] = useState(false);
  const [runArrays, setRunArrays] = useState([]);
  const [displayTable, setDisplayTable] = useState(false);
  const [plotClicked, setPlotClicked] = useState(false)

  let chartData = [];
  let individualData = [];
  let lower_limit, upper_limit, target;

  useEffect(() => {
    //Dropdown data=> cavity sets
    DataService.GetDefineResponse(session, mold)
      .then((res) => {
        const dimensionJSON = res.data["dimension"];

        const dataArray = Array.isArray(dimensionJSON)
          ? dimensionJSON
          : [dimensionJSON];

        setnoOfDimensions(dataArray);
      })
      .then(() => {
        setRenderComponent(true);
      });
  }, [session, mold, selectedIndex]);

  if (data) {
    let subArray = data.subarrays;
    let average_Array = data.average_Array;
    let parameters = data.parameters;
  

    if (parameters) {
      lower_limit = parameters["lsl"];

      upper_limit = parameters["usl"];

      target = parameters["target"];

      
    } else {
      // console.error("Parameters object is undefined.");
    }

    //Map out individual points 
    if (subArray) {
      for (let j = 0; j < subArray.length; j++) {
        for (let i = 0; i < subArray[j].length; i++) {
          individualData.push({ x: i + 1, y: subArray[j][i] });
        }
      }

      //   console.log("individualData:", individualData);
    } else {
      // console.error("SubArrays is undefined.");
    }

    if (average_Array) {
      // Iterate over each element in the average_Array
      for (let i = 0; i < average_Array.length; i++) {
        // Push object with x as the index plus 1, and y as the element itself
        chartData.push({ x: i + 1, y: average_Array[i] });
      }
    }
  }

  const tableData = (runs, samplesize) => {
    runArrays.length = 0;
    const newArray = [];
    const columnNames = [];

    //define size of each item
    const subArrayLength = runs.length / samplesize;


    //splitting data as per the size
    for (let i = 0; i < samplesize; i++) {
      const start = i * subArrayLength;
      const end = start + subArrayLength;

      newArray.push(runs.slice(start, end));
    }

    for (let i = 0; i < newArray[0].length; i++) {
      columnNames.push(`Run${i + 1}`);
    }

    newArray.unshift(columnNames);

    setRunArrays(newArray);
    // console.log("runArrays", newArray);
  };

  const Table = ({ data }) => {
    return (
      <table>
        {data.map((rowData, rowIndex) => (
          <tr key={rowIndex}>
            {rowData.map((cellData, cellIndex) => (
              <td key={cellIndex}>{cellData}</td>
            ))}
          </tr>
        ))}
      </table>
    );
  };

  const handleCompute = () => {
    return new Promise(async (resolve, reject) => {
      const selectedDimension = noOfDimensions[selectedIndex];

      try {
        DataService.SelectDropdown(session, {
          selected: selectedDimension,
        })
          .then((res) => {
            const runs = res.data.runvalues.split(",");
            const samplesize = res.data.variable_details.samplesize;

            tableData(runs, samplesize);
            // console.log(res.data);
          })
          .then(() => {
            DataService.GetSensitivityOutliers(session)
              .then((res) => {
                setData(res.data);

                // console.log("API Response of GetSensitivityOutliers", res.data);
              })
              .catch((err) => {
                // console.error("Error of GetSensitivityOutliers:", err);
              })

              .then(() => {
                setPlotClicked(true);
                setRenderComponent(true);
                setDisplayTable(true);
              });
          })

          .catch((err) => {
            // console.error("Error sending data to Django:", err);
          });

        resolve();
      } catch (error) {
        // console.error("Error sending data to selected_response API:", error);
        reject(error);
      }
    });
  };

  const handleIndexChange = (event) => {
    setSelectedIndex(parseInt(event.target.value, 10));
    setPlotClicked(false);
  };
  
  return (
    <>
      <div className="b-primary b-r-4 p-2">
        <div className="d-flex">
          <div className="col-md-2">
          <select value={selectedIndex} onChange={handleIndexChange}  className="mb-2 mt-2">
          <option value="" disabled>
                  Select a dimension
                </option>
            {noOfDimensions.map((dimension, index) => (
              <option key={index} value={index}>
                {dimension.charAt(0).toUpperCase()+dimension.slice(1)}
              </option>
            ))}
          </select>
          </div>
          <div className="col-md-1">
            <Button onClick={handleCompute} className="ml-1 mb-2 mt-2 btn btn-secondary btn-air-secondary">
              Plot Graph
            </Button>
          </div>
        </div>

        <div className="m-2">
          {renderComponent && plotClicked && (
            <div className="b-primary b-r-4 p-2">

              <div className="d-flex">

              <div className="col-md-6 b-primary p-2" style={{overflow: "auto" }}> 
              {displayTable && plotClicked && <Table data={runArrays} />}
              </div>

              <div className="col-md-6 b-primary p-2 ml-1 "> <ExperimentalRunSettings />  </div>

              </div>

              <div className="col-md-12">
              <Chart
              className="sensitivityChart"
                individualData={individualData}
                chartData={chartData}
                upperLimit={upper_limit}
                lowerLimit={lower_limit}
                average={target}
                responseName={noOfDimensions[selectedIndex]}
          
              />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SensitivityOutliers;
