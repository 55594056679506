import React, { useEffect, useState } from "react";
import {
  GridComponent,
  Inject,
  Toolbar,
  Page,
} from "@syncfusion/ej2-react-grids";
import { useHistory } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

// CSS
import "../../App.css";
import DataService from "../../../services/ApiService";
import { connect } from "react-redux";

const MaterialDatabase = ({ user }) => {
  var grid;

  const toolbar = ["Search"];

  const history = useHistory();

  const [SelectRowModal, setSelectRowModal] = useState(false);

  const [selectedRowIndexes, setSelectedRowIndexes] = useState({
    rowIdx: "",
    Material_Id: "",
  });

  const [column, setColumn] = useState([
    {
      field: "Material_Id",
      headerText: "Material ID",
      textAlign: "left",
      width: "90",
    },
    {
      field: "Base_Resin",
      headerText: "Resin",
      textAlign: "left",
      width: "90",
    },
    {
      field: "Manufacturer",
      headerText: "Supplier",
      textAlign: "left",
      width: "90",
    },
    {
      field: "Specific_Gravity",
      headerText: "Sp.Gr",
      textAlign: "left",
      width: "90",
    },
    {
      field: "Min_Melt_Temperature",
      headerText: "Min Melt Temp",
      textAlign: "left",
      width: "90",
    },
    {
      field: "Max_Melt_Temperature",
      headerText: "Max Melt Temp",
      textAlign: "left",
      width: "90",
    },
    {
      field: "Avg_Melt_Temperature",
      headerText: "Avg Melt Temp",
      textAlign: "left",
      width: "90",
    },
    {
      field: "Min_Mold_Temperature",
      headerText: "Min Mold Temp",
      textAlign: "left",
      width: "90",
    },
    {
      field: "Max_Mold_Temperature",
      headerText: "Max Mold Temp",
      textAlign: "left",
      width: "90",
    },
    {
      field: "Avg_Mold_Temperature",
      headerText: "Avg Mold Temp",
      textAlign: "left",
      width: "90",
    },
    {
      field: "Drying_Temperature",
      headerText: "Drying Temp",
      textAlign: "left",
      width: "90",
    },
    {
      field: "Drying_Time_Min",
      headerText: "Drying Temp Min",
      textAlign: "left",
      width: "90",
    },
    {
      field: "Drying_Time_Max",
      headerText: "Drying Temp Max",
      textAlign: "left",
      width: "90",
    },
    {
      field: "Max_Residence_Time",
      headerText: "Max Res Time",
      textAlign: "left",
      width: "90",
    },
  ]);

  const pathN = () => {
    history.push({
      pathname: "/database/Options/MaterialNew",
    });
  };

  const pathE = () => {
    // console.log(selectedRowIndexes.Material_Id);

    if (selectedRowIndexes.Material_Id) {
      let RowId = btoa(selectedRowIndexes.Material_Id);

      history.push({
        pathname: `/database/Options/${RowId}/MaterialEdit`,
      });
    } else {
      setSelectRowModal(!SelectRowModal);
    }
  };

  const pathV = () => {
    if (selectedRowIndexes.Material_Id) {
      let RowId = btoa(selectedRowIndexes.Material_Id);

      history.push({
        pathname: `/database/Options/${RowId}/MaterialView`,
      });
    } else {
      setSelectRowModal(!SelectRowModal);
    }
  };

  const pathD = () => {
    if (selectedRowIndexes.Material_Id) {
      let RowId = btoa(selectedRowIndexes.Material_Id);

      history.push({
        pathname: `/database/Options/${RowId}/MaterialDuplicate`,
      });
    } else {
      setSelectRowModal(!SelectRowModal);
    }
  };

  const [AllMaterialData, setAllMaterialData] = useState([]);

  const selectionSettings = { type: "Single" };

  const click = () => {
    // setSelectedRowIndexes(grid.getSelectedRowIndexes());

    setSelectedRowIndexes({
      rowIdx: grid.getSelectedRowIndexes(),
      Material_Id: grid.getSelectedRecords()[0]?.id,
    });
  };

  const [DeleteConfirm, setDeleteConfirm] = useState(false);

  const [DeleteConfirmHeader, setDeleteConfirmHeader] = useState("");

  const ToggleDeleteConfirm = () => {
    if (selectedRowIndexes.Material_Id) {
      setDeleteConfirm(!DeleteConfirm);

      setDeleteConfirmHeader(
        AllMaterialData.find(
          (value) => value.id === selectedRowIndexes.Material_Id
        ).Material_Id
      );

      // console.log(selectedRowIndexes);
    } else {
      setSelectRowModal(!SelectRowModal);
    }
  };

  function created(args) {
    if (grid) {
      document
        .getElementById(grid.element.id + "_searchbar")
        .addEventListener("keyup", (args) => {
          var gridObj =
            document.getElementById("MaterialGrid").ej2_instances[0];
          gridObj.search(args.target.value);
        });
    }
  }

  const OnDelete = () => {
    if (selectedRowIndexes.Material_Id) {
      const updatedRows = [...AllMaterialData].filter((value, idx) => {
        return value.id !== selectedRowIndexes.Material_Id;
      });

      setAllMaterialData(updatedRows);

      DataService.DeleteMaterialsData(selectedRowIndexes.Material_Id).then(
        (res) => {
          // console.log(res.data.Material_Data);

          ToggleDeleteConfirm();
        }
      );
    } else {
      setSelectRowModal(!SelectRowModal);
    }
  };

  const GetData = (id) => {
    DataService.GetMaterialsData(id).then((res) => {
      if (res.data.length > 0) {
        // console.log(res.data);

        let TempArray = [];

        for (let i = 0; i < res.data.length; i++) {
          TempArray.push(
            res.data[i].Material_Data[0] || res.data[i].Material_Data
          );

          TempArray[i]["id"] = res.data[i].Material_Id;

          // console.log(res.data[i].Material_Id);
        }

        setAllMaterialData(TempArray);
      } else {
        setAllMaterialData([]);
      }
    });

    DataService.GetFieldsData(id).then((res) => {
      // console.log(res.data)

      if (res.data.length > 0) {
        if (res.data[0].Material_Custom_Fields.length > 0) {
          // console.log([...column, ...res.data[0].Material_Custom_Fields]);

          setColumn([...column, ...res.data[0].Material_Custom_Fields]);
        }
      }
    });
  };

  useEffect(() => {
    if (user) {
      GetData(user.id);
    }
  }, [user]);

  return (
    <>
      <Modal isOpen={SelectRowModal} centered={true} style={{ width: "400px" }}>
        <ModalHeader> Nautilus </ModalHeader>
        <ModalBody>
          <span> Please click on a row to select. </span>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => setSelectRowModal(!SelectRowModal)}
          >
            {" "}
            Close{" "}
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={DeleteConfirm} centered={true} style={{ width: "400px" }}>
        <ModalHeader> Nautilus </ModalHeader>
        <ModalBody>
          <span>
            {" "}
            Do you want to delete Material Id <strong> {DeleteConfirmHeader} </strong> ?{" "}
          </span>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={OnDelete}>
            {" "}
            Delete{" "}
          </Button>
          <Button color="primary" onClick={ToggleDeleteConfirm}>
            {" "}
            Close{" "}
          </Button>
        </ModalFooter>
      </Modal>

      <div className="card p-3 ml-2">
        <div className="d-flex col-md-16">
          <div className="pt-2 pb-2 text-left">
            <button className="btn btn-info btn-air-info mr-2" onClick={pathN}>
              New
            </button>
          </div>

          <div className="pt-2 pb-2">
            <button
              className="btn btn-secondary btn-air-secondary mr-2"
              onClick={pathE}
            >
              Edit
            </button>
          </div>

          <div className="pt-2 pb-2 text-left">
            <button
              className="btn btn-primary btn-air-primary mr-2"
              onClick={pathV}
            >
              View
            </button>
          </div>

          <div className="pt-2 pb-2 text-left">
            <button
              className="btn btn-primary btn-air-primary mr-2"
              onClick={pathD}
            >
              Duplicate
            </button>
          </div>

          <div className="pt-2 pb-2 text-left">
            <button
              className="btn btn-warning btn-air-warning mr-2"
              onClick={ToggleDeleteConfirm}
            >
              Delete
            </button>
          </div>

          <div className="pt-2 pb-2">
            <button className="btn btn-secondary btn-air-seccondary mr-2">
              Print
            </button>
          </div>
        </div>

        <div className="table-responsive">
          <table className="table">
            <tr>
              <td onClick={click} id="DBTable">
                <GridComponent
                  ref={(g) => (grid = g)}
                  id="MaterialGrid"
                  toolbar={toolbar}
                  dataSource={AllMaterialData}
                  allowPaging={true}
                  pageSettings={{ pageSize: 10 }}
                  columns={column}
                  selectionSettings={selectionSettings}
                  created={created.bind(this)}
                >
                  <Inject services={[Toolbar, Page]} />
                </GridComponent>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(MaterialDatabase);
