import React, { Fragment, useState } from "react";
import "../assets/custom-stylesheet/login_style.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { signup } from "../actions/auth";
// import Recaptcha from 'react-recaptcha';
// import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer, toast } from "react-toastify";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import Captcha from "./Captcha";

const Signup = ({ signup }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const [IsVerified, setIsVerified] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    re_password: "",
  });

  const [SubmitModal, setSubmitModal] = useState({
    message: "",
    visibility: false
  });

  // Regex for at least one letter, one number, and one special character
  const mixedRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/;

  const { name, email, password, re_password } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const generateCaptcha = () => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let captcha = "";
    for (let i = 0; i < 6; i++) {
      captcha += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return captcha;
  };

  const [captcha, setCaptcha] = useState(generateCaptcha());
  const [userInput, setUserInput] = useState("");

  const [error, setError] = useState("");

  const refreshCaptcha = () => {
    setCaptcha(generateCaptcha());
    setUserInput(""); // Clear input field
  };

  const ToggleSubmitModal = (msg) => {
    setSubmitModal({
      message: msg,
      visibility: !SubmitModal.visibility
    })
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (password !== re_password) {

      ToggleSubmitModal("Entered password's does not match")

    }

    else if (!mixedRegex.test(password) || !mixedRegex.test(re_password)) {

      setError("Password must contain at least one letter, one number, and one special character.");

    }

    else if (userInput !== captcha) {

      toast("CAPTCHA Incorrect", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })

    }
    else {
      const res = await signup(name, email, password, re_password);

      // console.log(res)

      if (res && res.message) {

        ToggleSubmitModal(`${res.message}`)

      }
      else if (res) {

        ToggleSubmitModal(`${res} on ${formData.email}`)

      }

      setError("")

    }
  }

  return (
    <Fragment>
      <Modal isOpen={SubmitModal.visibility} centered={true} style={{ width: "650px" }}>
        <ModalHeader>
          {"Nautilus"}
        </ModalHeader>

        <ModalBody>
          <span> {SubmitModal.message} </span>
        </ModalBody>

        <ModalFooter>
          <Button color="fourth" onClick={() => ToggleSubmitModal(null)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="authentication-main">
            <div className="row">
              <div className="col-sm-12 p-0">
                <div className="auth-innerright">
                  <div className="authentication-box">
                    <div className="card mt-4" style={{ width: "100%" }}>
                      <div className="card-body card_css">
                        <div className="text-center">
                          <h5 className="card_head">Sign Up</h5>
                        </div>
                        <form
                          className="theme-form"
                          onSubmit={(e) => onSubmit(e)}
                        >
                          <div className="form-group">
                            <label htmlFor="name"> Name: </label>
                            <input
                              className="form-control"
                              name="name"
                              placeholder="Enter Your Full Name"
                              value={name}
                              onChange={(e) => onChange(e)}
                              required
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="email"> Email: </label>

                            <input
                              className="form-control"
                              name="email"
                              placeholder="Enter Your Email Address"
                              type="email"
                              onChange={(e) => onChange(e)}
                              value={email}
                              required
                            />
                          </div>
                          {error && <p style={{ color: "red" }}>{error}</p>}
                          <div className="form-group">
                            <label> Password: </label>
                            <div className="d-flex mt-2">
                              <input
                                className="form-control"
                                placeholder="Make a password using alphabets and number"
                                onChange={(e) => onChange(e)}
                                value={password}
                                minLength={8}
                                name="password"
                                type={showPassword ? "text" : "password"}
                                required
                              />

                              <i
                                className="far fa-eye m-2"
                                style={{ cursor: "pointer" }}
                                onClick={() => setShowPassword(!showPassword)}
                              ></i>
                            </div>
                          </div>
                          <div className="form-group">
                            <label> Retype Password: </label>
                            <div className="d-flex">
                              <input
                                className="form-control"
                                placeholder="Make a password using alphabets and number"
                                type={showPassword2 ? "text" : "password"}
                                minLength={8}
                                onChange={(e) => onChange(e)}
                                value={re_password}
                                name="re_password"
                                required
                              />

                              <i
                                className="far fa-eye m-2"
                                style={{ cursor: "pointer" }}
                                onClick={() => setShowPassword2(!showPassword2)}
                              ></i>
                            </div>
                          </div>
                          <div>
                            <Captcha captcha={captcha} userInput={userInput} setUserInput={setUserInput} refreshCaptcha={refreshCaptcha} />
                          </div>
                          <div className="form-group form-row mt-3 mb-0">
                            <button
                              className="btn btn-primary btn-block"
                              type="submit"
                            >
                              {" "}
                              Sign In{" "}
                            </button>
                          </div>
                          <div className="login_links text-center">
                            <div className="mt-2">
                              {" "}
                              Are you already a user ?{" "}
                              <Link
                                className="btn-link text-capitalize sign_in"
                                to="/login"
                              >
                                {" "}
                                Sign In{" "}
                              </Link>
                            </div>
                          </div>
                        </form>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default connect(null, { signup })(Signup);
