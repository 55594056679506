import React from "react";
import { Card, CardBody, CardHeader, Button } from "reactstrap";
import { useHistory } from "react-router-dom";

const ModuleCard = ({
  NavigateToModule,
  SessionData,
  // SessionName,
  ModuleTitle,
  ModuleName,
  Icon,
  isSessionBased,
  ToggleCreateSessionState,
  SelectedMoldData,
  // ToggleManageSessionModal
}) => {
  const history = useHistory();

  const LoadPage = (name) => {
    var Mold_Id = btoa(SelectedMoldData[0].id);
    var Mold_Name = btoa(SelectedMoldData[0].Mold_Id);

    if (name === "Model_Trouble_Shooting") {
      history.push({
        pathname: `${Mold_Id}/MoldTroubleshooting`,
      });
    }

    if (name === "Documents") {
      history.push({
        pathname: "/Documents",
      });
    }

    if (name === "Manage_Sessions") {
      history.push({
        pathname: `${Mold_Id}/ManageSessions`,
      });
    }

  };

  const CreateSession = (ModuleName, ModuleTitle) => {
    function isString(input) {
      return typeof input === "string";
    }

    if (isString(ModuleName)) ToggleCreateSessionState(ModuleName, ModuleTitle);
  };

  return (
    <div>
      <Card>
        <CardHeader
          className="d-flex justify-content-evenly bg-light b-b-primary
         border-3"
        >
          <div className="icon-container">
            {/* {Icon} */}
            <img
              src={Icon}
              alt={ModuleTitle}
              className="menu-icon"
              style={{ width: "4rem", height: "4rem" }}
            />
          </div>
          <span
            style={{
              fontWeight: "bold",
              fontSize: "1.2em",
              paddingTop: "20px",
            }}
            className="ml-2"
          >
            {" "}
            {ModuleTitle}{" "}
          </span>
        </CardHeader>

        <CardBody>
          {isSessionBased ? (
            <>
              <div className="d-flex mb-3">
                {/* <div className="col-md-6"></div> */}

                <div className="col-md-6">
                  <select
                    className="form-control border-3"
                    name={ModuleName}
                    style={{ width: "8vw" }}
                    onChange={NavigateToModule}
                    {...(SelectedMoldData.length === 0
                      ? { readOnly: true }
                      : {})}
                  >
                    <option value={null}>
                      {" "}
                      {SessionData.length > 0 ? "Select Session" : ""}{" "}
                    </option>
                    {SessionData.map((SessionName, key) => (
                      <option value={SessionName.id} key={key}>
                        {" "}
                        {SessionName.Session_Name}{" "}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-md-6"
                >
                  <Button
                    color="info"
                    style={{ width: "8vw" }}
                    className="btn btn-info"
                    onClick={() => CreateSession(ModuleName, ModuleTitle)}
                    {...(SelectedMoldData.length === 0
                      ? { disabled: true }
                      : {})}
                  >
                    Create Session
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="d-flex mb-3">
                <div className="col-md-6">
                  <Button
                    color="info"
                    className="btn btn-info"
                    {...(SelectedMoldData.length === 0
                      ? { disabled: true }
                      : {})}
                    onClick={() => LoadPage(ModuleName)}
                  >
                    {" "}
                    Open{" "}
                  </Button>
                </div>
              </div>
            </>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default ModuleCard;
