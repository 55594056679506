import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import "../App.css";
import "../../assets/custom-stylesheet/grid_stylecss.css";
import { createSpinner, showSpinner } from "@syncfusion/ej2-popups";
import { useHistory } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Header2 from "../common/header-component/header2";
import DataService from "../../services/ApiService";
import { connect } from "react-redux";
import Loader from "../../assets/Loader";

const QualitySessions = ({ user }) => {
  const history = useHistory();
  // const pathD = () => {
  //   history.push({ pathname: `/dashboard/DOE/doe-new-session` });
  // };


  // Redirect's to Melt Preparation of that respective session
  const handleSession = (session) => {
    // Using the "btoa" method to encrypt the URL so that the exact ID should not be visible
    var Session_Id = btoa(session.id);
    var Session_Name = btoa(session.Session_Name);

    createSpinner({
      // Specify the target for the spinner to show
      target: document.getElementById("Container"),
    });

    showSpinner(document.getElementById("Container"));

    setTimeout(() => {
      // history.push(`/dashboard/${Session_Id}/meltpreparation`)

      // Now the final Once all the Data fetching and storing in the respective Array's is done we navigate to the reports with the Data Array using the "useHistory" hook.
      // history.push(
      //     // `/dashboard/${Session_Id}/${Session_Name}/doe`,
      //     ``,
      //     { session: session.id },
      // )

      history.push({
        pathname: `/dashboard/${Session_Id}/DOE/summary`,
        state: { mold: moldId },
      });
    }, 100);
  };

  // To Store the Mold's
  const [MoldData, setMoldData] = useState([]);
  const [moldId, setMoldId] = useState();

  const [isLoading, setIsLoading] = useState(true);

  // To toggle Create Session modal
  const [SelectMoldModal, setSelectMoldModal] = useState(false);

  const ToggleSelectMoldModal = () => {
    setSelectMoldModal(!SelectMoldModal);
  };

  // To toggle Create Session modal
  const [SessionModal, setSessionModal] = useState();

  const ToggleSessionModal = () => {
    setSessionModal(!SessionModal);
  };

  // These are the state's which store the Session's created by the user.
  const [SessionData, setSessionData] = useState([]);

  // An Local Object to store the Session Data which is stored in the Above Session Array.
  const [addSessionData, setAddSessionData] = useState({
    Session_Name: "",
    Date: "",
  });

  // This Event store's the Local Session Object in the main Session Data array.
  const handleAddFormSubmit2 = (event) => {
    event.preventDefault();

    const newSession = {
      mold: moldId,
      Session_Name: addSessionData.Session_Name,
      Date: addSessionData.Date,
      ExperimentDesc : addSessionData.Session_description || "-"
    };

    // console.log(newSession)

    // // This method is exported from services folder which has different method's of saving and fetching data.
    DataService.SaveDoeSession(newSession)
      .then((res) => {
        if (res.data.message) {
        } else {
          setSessionData([...SessionData, res.data]);
          setAddSessionData([]);
        }

        // console.log("sessionName", addSessionData.Session_Name)
        // console.log("newsession", newSession)
        // console.log("res.data", res.data.id)

        var Session_Id = btoa(res.data.id);
        var session_name = btoa(addSessionData.Session_Name);

        history.push({
          pathname: `/dashboard/${Session_Id}/${session_name}/doe`,
          state: {
            mold: moldId,
            seshdata: newSession,
            moldName: MoldData[moldId - 1].Mold_Id,
            moldData :MoldData[moldId-1].Part_Data
          },
        });
      })
      .catch((err) => {});
  };


  // The event to store the Session Data into the local Object
  const handleAddFormChange2 = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = { ...addSessionData };
    newFormData[fieldName] = fieldValue;

    setAddSessionData(newFormData);
  };

  // console.log(addSessionData)
  let defaultDate, date;

  let NewDate = new Date();
  let TodaysDate = NewDate.toISOString().split("T")[0];

  const [error, setError] = useState(false);

  const toggle = () => {
    if (moldId && moldId !== 1) {
      ToggleSessionModal();
      setError(false);

      // console.log(moldId)
    } else {
      ToggleSelectMoldModal();
    }
  };

  const handleSubmit = (e) => {
    if (!addSessionData.Session_Name) {
      setError(true);
    } else if (!addSessionData.Date) {
      date = new Date();
      defaultDate = date.toDateString();
      addSessionData.Date = defaultDate;
      ToggleSessionModal();
      handleAddFormSubmit2(e);
    } else {
      ToggleSessionModal();
      handleAddFormSubmit2(e);
      addSessionData.Session_Name = "";
    }
  };

  const setDate = (e) => {
    if (e.target.value) {
      date = new Date(e.target.value);
      defaultDate = date.toDateString();
      addSessionData.Date = defaultDate;
    }
  };

  const onClose = () => {
    addSessionData.Session_Name = "";
    ToggleSessionModal();
    setError(false);
  };

  const handleGetMold = (id) => {
    DataService.FetchMold(id)
      .then((res) => {
        if (res.data.message) {
          setMoldData([]);
          setIsLoading(false);
        } else {
          setMoldData(res.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {});
  };

  const handleGetSessions = (moldId) => {
    // setIsLoading(true);

    if (moldId && moldId !== 1) {
      setIsLoading(true);

      setTimeout(() => {
        DataService.FetchDoeSession(moldId)
          .then((res) => {
            if (res.data.message) {
              setSessionData([]);
              setIsLoading(false);
            } else {
              setSessionData(res.data);
              setIsLoading(false);
            }
          })
          .catch((err) => {});
      }, 500);
    } else {
      setSessionData([]);
    }
  };

  useEffect(() => {
    // First Checking for the user
    if (user) {
      // This event has a GET api in it
      handleGetMold(user.id);
    } else {
      setSessionData([]);
    }
  }, [user]);

  const setMold = (e) => {
    var Id = e.target.value;

    setMoldId(Id);
  };

  return (
    <>
      <Header2 Title="Current Sessions" />

      {isLoading ? <Loader /> : <></>}

      <Modal isOpen={SelectMoldModal} centered={true}>
        <ModalHeader toggle={ToggleSelectMoldModal}>
          {" "}
          Select a mold.{" "}
        </ModalHeader>
        <ModalBody>
          <span> Please select a mold before creating a session. </span>
        </ModalBody>
        <ModalFooter>
          <Button color="dark" onClick={ToggleSelectMoldModal}>
            {" "}
            Close{" "}
          </Button>
        </ModalFooter>
      </Modal>

      <div className="container-fluid">
        <div className="d-flex justify-content-between ml-3 pt-3 pb-3">
          <div className="d-flex">
            <div>
              <span
                className="BreadCrum"
                onClick={() => history.push("/dashboard/mold")}
                style={{ fontSize: "14px", color: "blue" }}
              >
                {" "}
                Mold{" "}
              </span>
            </div>
            <div>
              <span className="BreadCrum" style={{ fontSize: "16px" }}>
                {" "}
                {">"}{" "}
              </span>
            </div>
            <div>
              <span style={{ fontSize: "14px" }}>
                {" "}
                DOE Sessions{" "}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div id="Container" className="container-fluid">
        <div className="card ml-4 mr-4 mt-2 mb-4">
          <div className="row d-flex justify-content-evenly">
            <div className="col-md-2 m-4">
              <button
                className="btn btn-primary mt-4 mr-4 mb-2 ml-4"
                type="button"
                onClick={toggle}
              >
                {" "}
                Create session{" "}
              </button>
            </div>

            <div className="d-flex justify-content-between m-3 col-md-6">
              <div className="col-md-10"></div>

              <div className="col-md-4 ml-4">
                <label> Select Mold: </label>

                <select
                  className="form-control"
                  onChange={setMold}
                  onClick={() => handleGetSessions(moldId)}
                >
                  <option value={1}> Select a mold </option>

                  {MoldData.map((mold, key) => (
                    <option key={key} value={mold.id}>
                      {" "}
                      {mold.Mold_Id}{" "}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          {/* Grid to display the session's of the selected Mold */}
          <div className="m-2">
            <form autoComplete="off">
              <div className="viscosity_table">
                <Table striped bordered hover responsive variant="light">
                  <thead>
                    <tr>
                      <th className="Pressure_Heading">
                        <span> Session Name </span>
                      </th>
                      <th className="Pressure_Heading">
                        <span> Date </span>
                      </th>
                    </tr>
                  </thead>

                  <tbody className="grid_style">
                    {SessionData.map((session, sessionId) => (
                      <tr key={SessionData[sessionId].id}>
                        <td>
                          {" "}
                          <input
                            type="text"
                            className="form-control session-link"
                            title="Go to DOE"
                            name="Session_Name"
                            value={session.Session_Name}
                            onClick={() => handleSession(session)}
                            readOnly
                          />{" "}
                        </td>

                        <td>
                          {" "}
                          <input
                            type="text"
                            className="form-control"
                            name="Date"
                            value={session.Date}
                            readOnly
                          />{" "}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </form>
          </div>
        </div>

        <div>
          <form>
            <Modal
              isOpen={SessionModal}
              centered={true}
              toggle={ToggleSessionModal}
            >
              <ModalHeader toggle={ToggleSessionModal}>
                {" "}
                Add Session{" "}
              </ModalHeader>
              <ModalBody>
                <div>
                  {" "}
                  {error ? (
                    <span style={{ color: "red" }}>
                      {" "}
                      *Please enter session name{" "}
                    </span>
                  ) : (
                    ""
                  )}{" "}
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="lbl_style">Session Name :</label>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      name="Session_Name"
                      type="text"
                      onChange={handleAddFormChange2}
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="lbl_style">Date :</label>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="input-group">
                      <input
                        className="form-control"
                        id="dateRequired"
                        type="date"
                        name="Date"
                        defaultValue={TodaysDate}
                        onChange={setDate}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="lbl_style">
                        Experiment description{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <textarea
                      rows="2"
                      className="form-control"
                      name="Session_description"
                      type="text"
                      onChange={handleAddFormChange2}
                      required
                    />
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>

                <Button
                  color="primary"
                  type="submit"
                  onClick=/*{pathD}*/ {handleSubmit}
                >
                  {" "}
                  Create
                </Button>
                <Button color="warning" onClick={onClose}>
                  {" "}
                  Cancel{" "}
                </Button>
              </ModalFooter>
            </Modal>
          </form>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(QualitySessions);
