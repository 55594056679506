import React from "react";
import { Table } from "reactstrap";

const MoldTable = ({ BaseUnits, MoldData, BaseUnitsArray }) => {
  const SelectedMoldUnitData = MoldData.length > 0 ? MoldData[0].Units : [];

  const partColumn =
    MoldData.length === 0
      ? [
        {
          id: 0,
          Part: "",
          edit: false,
          delete: false,
        },
      ]
      : MoldData[0].Part_Details;

  const PartData =
    MoldData.length === 0
      ? [
        {
          id: 0,
          edit: false,
          Cavity_Data: "Part Description *",
        },
        {
          id: 1,
          edit: true,
          Cavity_Data: "Part Number *",
        },
        {
          id: 2,
          edit: true,
          Cavity_Data: "Number of Cavities *",
        },
        {
          id: 3,
          edit: true,
          Cavity_Data: "Starting Cavity Number *",
        },
        {
          id: 4,
          edit: true,
          Cavity_Data: "Weight of one Part",
        },
        {
          id: 5,
          edit: true,
          Cavity_Data: "Number Of Runners",
        },
        {
          id: 6,
          edit: true,
          Cavity_Data: "Runner Weight",
        },
        {
          id: 7,
          edit: true,
          Cavity_Data: "Part Projected Area",
        },
        {
          id: 8,
          edit: true,
          Cavity_Data: "Runner Projected Area",
        },
      ]
      : MoldData[0].Part_Data;

  return (
    <Table className="table-responsive table-bordered">
      <tr>
        {partColumn.map((value, key1) => (
          <React.Fragment key={key1}>
            <th key={value.id}>
              {key1 === 0 ? (
                <span> {value.Part} </span>
              ) : (
                <span> {value.Part_No} </span>
              )}
            </th>
          </React.Fragment>
        ))}
      </tr>

      {PartData.map((value, key1) => (
        <tr key={value.id}>
          {partColumn.map((value2, key2) => (
            <React.Fragment key={value2.id}>
              {value2.edit === false ? (
                <td style={{ backgroundColor: "#fff" }} key={value2.id}>
                  {key1 === 4 ? (
                    <input
                      name="WeightUnit"
                      data-fieldname={"Weight_of_one_Part"}
                      className="form-control w-100"
                      readOnly
                      value={`Weight of one Part (${BaseUnits?.find(
                        (unit) =>
                          unit?.unit_id ===
                          parseInt(SelectedMoldUnitData?.Weight_of_one_Part?.unit_id ?? 0)
                      )?.unit_name || ""
                        })`}
                    />
                  ) : key1 === 6 ? (
                    <input
                      name="WeightUnit"
                      className="form-control w-100"
                      readOnly
                      value={`Runner Weight (${BaseUnits?.find(
                        (unit) =>
                          unit.unit_id ===
                          parseInt(
                            SelectedMoldUnitData?.Runner_Weight?.unit_id ?? 0
                          )
                      )?.unit_name || ""
                        })`}
                    />
                  ) : key1 === 7 ? (
                    <input
                      className="form-control w-100"
                      readOnly
                      value={`Part Projected Area (${BaseUnits?.find(
                        (unit) =>
                          unit.unit_id ===
                          parseInt(
                            SelectedMoldUnitData?.Part_Projected_Area?.unit_id ?? 0
                          )
                      )?.unit_name || ""
                        })`}
                    />
                  ) : key1 === 8 ? (
                    <input
                      className="form-control"
                      readOnly
                      value={`Runner Projected Area (${BaseUnits?.find(
                        (unit) =>
                          unit.unit_id ===
                          parseInt(
                            SelectedMoldUnitData?.Runner_Projected_Area
                              ?.unit_id ?? 0
                          )
                      )?.unit_name || ""
                        })`}
                      style={{ width: 200 }}
                    />
                  ) : (
                    <>{value.Cavity_Data}</>
                  )}
                </td>
              ) : (
                <React.Fragment key={value2.id}>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      data-index={key1}
                      name={`Part${key2}`}
                      value={PartData[key1][`Part${key2}`] || ""}
                      style={{ width: 100 }}
                      readOnly
                    />
                  </td>
                </React.Fragment>
              )}
            </React.Fragment>
          ))}
        </tr>
      ))}
    </Table>
  );
};

export default MoldTable;
