import React, { useEffect, useState } from 'react'
import '../App.css';
import '../../assets/custom-stylesheet/grid_stylecss.css';
import { SpreadsheetComponent, SheetsDirective, SheetDirective, getCell, focus } from '@syncfusion/ej2-react-spreadsheet';

import { getRangeIndexes } from '@syncfusion/ej2-spreadsheet';

// Hook to get the parameter's from the URL
import { useParams } from 'react-router-dom';

const CavityGrid = ({ column, PopulateSheet, CavityGridData, setPercentage, setTotal, setAverage, setRange, setMaxPart, setMinPart, CavitySpreadsheet, setColToBeDeleted, colCount, setCavityGridData, setColCount, showAlert }) => {

    // To get the Session Id from url using useParams hook
    var { moldId, sessionId } = useParams();

    // Variable to store the a mold Id and Session Id
    const [mold_Id, setmold_Id] = useState();
    const [session_Id, setsessionId] = useState();

    let isPaste = false;

    // useEffect Hook to do the calculation of the data entered in the Grid
    useEffect(() => {

        setmold_Id(atob(moldId))
        setsessionId(atob(sessionId))

        const Total_Average = () => {

            const calculateAverage = (arr) => {
                if (arr.length === 0) {
                    return 0;
                }

                const sum = arr.reduce((acc, num) => acc + num, 0);
                return sum;
            };

            // console.log( CavityGridData )

            let columnTotal = [], columnAverage = [], columnRange = [], columnMaxPart = [], columnMinPart = [], columnPercent = [];

            for (let i = 1; i < column.length; i++) {

                let total = 0, average = 0, range, Range_Array = [], Range_Array_for_Percent = [], max, min, percent = [];

                const compare = (a, b) => {
                    return a - b;
                }

                for (let j = 1; j <= CavityGridData.length; j++) {

                    if (CavityGridData[j - 1][`value${i}`] != null) {

                        Range_Array_for_Percent.push(parseFloat(CavityGridData[j - 1][`value${i}`]))

                    }

                    if (CavityGridData[j - 1][`value${i}`] != null) {

                        Range_Array.push(parseFloat(CavityGridData[j - 1][`value${i}`]))

                    }
                    else {

                        Range_Array.push(0)

                    }

                    // console.log( Range_Array )

                    total = calculateAverage(Range_Array)

                    average = Number(parseFloat(total) / parseInt(Range_Array.length)).toFixed(3)

                    const Sorted_Array = Range_Array.sort(compare)

                    // console.log(Sorted_Array)

                    max = Math.max(...Sorted_Array)

                    min = Math.min(...Sorted_Array)

                    range = Number(Math.max(...Sorted_Array) - Math.min(...Sorted_Array)).toFixed(2)

                }

                // console.log(Range_Array_for_Percent)

                for (let k = 1; k <= Range_Array_for_Percent.length; k++) {

                    percent.push(isNaN((Range_Array_for_Percent[k - 1] - average) * 100 / average) ? 0 : Number(((Range_Array_for_Percent[k - 1] - average) * 100 / average).toFixed(3)))

                }

                // console.log(percent)

                columnPercent[i - 1] = percent
                setPercentage(columnPercent)

                columnTotal[i - 1] = total
                setTotal(columnTotal)

                columnAverage[i - 1] = parseFloat(average)
                setAverage(columnAverage)

                columnRange[i - 1] = parseFloat(range)
                setRange(columnRange)

                columnMaxPart[i - 1] = max
                setMaxPart(columnMaxPart)

                columnMinPart[i - 1] = min
                setMinPart(columnMinPart)

            }
        }

        Total_Average()

    }, [moldId, session_Id, mold_Id, sessionId, CavityGridData, column, column.length, setAverage, setMaxPart, setMinPart, setPercentage, setRange, setTotal])

    // ************************CavitySpreadsheet events start from here *************************
    const protectSettings = { selectCells: true };

    const scrollSettings = { isFinite: true };

    let dataArray = [];

    const getIndex = () => {

        // Getting the index of the selected row and storing in a variable
        let cell = CavitySpreadsheet.current.getActiveSheet().activeCell;

        let cellIdx = getRangeIndexes(cell);

        if (column.length <= 2 || cellIdx[1] === 0) {
            setColToBeDeleted(null);
        }
        else {
            setColToBeDeleted(cellIdx[1])
        }

        // Getting the data from the CavitySpreadsheet cells
        let dataObj = {};

        // var usedRowIdx = CavitySpreadsheet.current.getActiveSheet().usedRange.rowIndex;

        for (let i = 0; i < CavityGridData.length; i++) {

            for (let j = 0; j < column.length; j++) {

                if (j === 0) {

                    dataObj["Cavity_No"] = !getCell(i, j, CavitySpreadsheet.current.getActiveSheet()).value ? 0 : getCell(i, j, CavitySpreadsheet.current.getActiveSheet()).value

                    // console.log(!getCell(i, j, CavitySpreadsheet.current.getActiveSheet()).value ? 0 : getCell(i, j, CavitySpreadsheet.current.getActiveSheet()).value)

                }
                else {

                    dataObj[`value${j}`] = !getCell(i, j, CavitySpreadsheet.current.getActiveSheet()).value ? 0 : getCell(i, j, CavitySpreadsheet.current.getActiveSheet()).value

                    // console.log(!getCell(i, j, CavitySpreadsheet.current.getActiveSheet()).value ? 0 : getCell(i, j, CavitySpreadsheet.current.getActiveSheet()).value)

                }

            }

            dataArray.push(dataObj)

            dataObj = {}

        }

        setCavityGridData(dataArray);

        // CavitySpreadsheet.current.refresh()

        // console.log(dataArray)

    }

    function onCreated() {

        //Applies data validation to specified range in the active sheet.
        CavitySpreadsheet.current.addDataValidation(
            {
                type: 'Decimal',
                operator: 'Between',
                value1: '-1000.0',
                value2: '1000.0',
            },
            `A1:Z${CavityGridData.length}`
        );

        focus(CavitySpreadsheet.current.element);

    };

    const CellSave = (args) => {

        // console.log(args.oldValue)

        if (isNaN(args.value)) {

            CavitySpreadsheet.current.updateCell({ value: args.oldValue || null }, args.address);

        }

    }

    const cellEditing = (args) => {

        if (args.value !== args.oldValue && !isNaN(args.value)) {

            // console.log(args.value)

            CavitySpreadsheet.current.updateCell({ value: args.value }, args.address);

            showAlert.current = true

            getIndex()

        } else {

            showAlert.current = false

        }

    };

    function beforeCellUpdate(args) {

        // Skip the cell update for paste action that contains characters, If you need you can skip this for all type of actions performed in the spreadsheet.
        if (isPaste) {

            let pastedValue = args.cell.value;

            // Match alphabets and special characters
            var regex = /[a-zA-Z]/g;

            if (pastedValue && pastedValue.toString().match(regex)) {
                args.cancel = true;
            }

            isPaste = false;

        }

    }

    function actionBegin(args) {

        if (args.args.eventArgs && args.args.eventArgs.requestType === 'paste') {
            isPaste = true;
        }

        if (args.action === 'clipboard' && args.args.eventArgs.requestType === 'paste') {
            args.args.eventArgs.type = 'Values';
            showAlert.current = true;
        }

    }

    function UnlockCells() {

        // console.log(row.current)

        CavitySpreadsheet.current.lockCells(
            `B1:Z${CavityGridData.length}`,
            false
        );

        // CavitySpreadsheet.current.setColumnsWidth(80, ['A:Z']);

    }

    const dialogBeforeOpen = (args) => {

        if (args.dialogName === 'EditAlertDialog') {
            args.cancel = true
        }
        else if (args.dialogName === 'ValidationErrorDialog') {
            args.cancel = true;
        }

    }

    const beforeCellRenderHandler = (args) => {

        PopulateSheet()

        // setColCount(column.length)

        // Condition to check whether the rendered element is header cell.
        for (let i = 0; i < column.length; i++) {

            if (
                args.colIndex === i &&
                args.element.classList.contains('e-header-cell')
            ) {
                const text = column[i].header
                args.element.innerText = text;
            }

        }

    }

    return (
        <>

            <div>

                <div className="spreadsheet" id="Cavity_Sheet" onMouseEnter={UnlockCells} tabIndex={1}>

                    <SpreadsheetComponent
                        onBlur={getIndex}
                        cellEdited={getIndex}
                        cellEditing={cellEditing}
                        ref={CavitySpreadsheet}
                        width={"100%"}
                        height={210}
                        showFormulaBar={false}
                        showSheetTabs={false}
                        showRibbon={false}
                        dialogBeforeOpen={dialogBeforeOpen.bind(this)}
                        scrollSettings={scrollSettings}
                        beforeCellRender={beforeCellRenderHandler}
                        cellSave={CellSave.bind(this)}
                        created={onCreated}
                        beforeCellUpdate={beforeCellUpdate.bind(this)}
                        actionBegin={actionBegin.bind(this)}
                        allowAutoFill={false} enableContextMenu={false} allowImage={false}
                    >

                        <SheetsDirective>

                            <SheetDirective
                                rowCount={CavityGridData.length}
                                colCount={colCount}
                                isProtected={true}
                                protectSettings={protectSettings}
                            >

                            </SheetDirective>

                        </SheetsDirective>

                    </SpreadsheetComponent>

                </div>
            </div>
        </>
    )
}


export default CavityGrid;