import React, { useState, useRef, useEffect } from "react";
import "../assets/custom-stylesheet/login_style.css";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { login, logoutPrevSession } from "../actions/auth";
// import Recaptcha from "react-recaptcha";
// import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer, toast } from "react-toastify";
import Captcha from "./Captcha";

const LogIn = ({ login, logoutPrevSession, user }) => {
  const history = useHistory();

  let UserEmail = sessionStorage.getItem("UserEmail");

  useEffect(() => {
    if (UserEmail) logoutPrevSession(UserEmail);
  }, []);

  const [showPassword, setShowPassword] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState(null);

  const formData = useRef({
    email: "",
    password: "",
  });

  const onChange = (e) =>
  (formData.current = {
    ...formData.current,
    [e.target.name]: e.target.value,
  });

  const generateCaptcha = () => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let captcha = "";
    for (let i = 0; i < 6; i++) {
      captcha += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return captcha;
  };

  const [captcha, setCaptcha] = useState(generateCaptcha());
  const [userInput, setUserInput] = useState("");

  const refreshCaptcha = () => {
    setCaptcha(generateCaptcha());
    setUserInput(""); // Clear input field
  };

  const onSubmit = async (e) => {

    e.preventDefault();

    if (userInput !== captcha) {

      toast("CAPTCHA Incorrect", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })

    } else {

      const response_msg = await login(
        formData.current.email,
        formData.current.password
      );

      if (response_msg === true) {
        history.push("/dashboard/mold");

        toast("Logged In Successfully !!!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        setErrorMessage(response_msg);
      }
    }

    refreshCaptcha()
  };

  const handleSendEmail = () => {

    history.push("/user/send-reset-login-status-email/")

  }

  return (
    <>
      <div>
        <div className="page-wrapper">
          <div className="container-fluid p-0">
            <div className="authentication-main">
              <div className="row">
                <div className="col-md-12">
                  <div className="auth-innerright">
                    <div className="authentication-box">
                      <div className="card mt-4" style={{ width: "90%" }}>
                        {ErrorMessage != null &&
                          ErrorMessage.includes("active session") ? (
                          <div
                            className="danger-alert d-flex flex-column align-items-center"
                            role="alert"
                          >
                            <div>
                              <span> {ErrorMessage} </span>
                            </div>
                            <div>
                              <strong>
                                Please log out from the previous session.
                              </strong>
                            </div>
                            <span> OR </span>
                            <div>
                              <strong>
                                Send an email to log out remotely by clicking
                              </strong>
                              <span
                                className="p-1"
                                style={{ cursor: "pointer", color: "#fde701" }}
                                onClick={handleSendEmail}
                              >
                                <strong> here. </strong>
                              </span>
                            </div>
                          </div>
                        ) : ErrorMessage != null ? (
                          <div
                            className="danger-alert alert-dismissible fade show"
                            role="alert"
                          >
                            <div>
                              <span> {ErrorMessage} </span>
                            </div>
                          </div>
                        ) : null}

                        <div className="card-body card_css">

                          <div className="text-center">
                            <h5 className="card_head"> LOGIN </h5>
                          </div>

                          <form className="theme-form" onSubmit={onSubmit}>

                            <div className="form-group">
                              <label className="col-form-label pt-0">
                                {" "}
                                Email{" "}
                              </label>

                              <input
                                className="form-control"
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={(e) => onChange(e)}
                                placeholder="Please Enter Your Email"
                                required
                              />
                            </div>

                            <div className="form-group">
                              <label className="col-form-label">
                                {" "}
                                Password{" "}
                              </label>

                              <div className="d-flex mt-2">
                                <input
                                  className="form-control"
                                  placeholder="Enter Your Password"
                                  onChange={(e) => onChange(e)}
                                  value={formData.password}
                                  minLength={8}
                                  name="password"
                                  type={showPassword ? "text" : "password"}
                                  required
                                />

                                <i
                                  className="far fa-eye m-2"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => setShowPassword(!showPassword)}
                                ></i>
                              </div>
                            </div>

                          </form>

                          <div>

                            <Captcha captcha={captcha} userInput={userInput} setUserInput={setUserInput} refreshCaptcha={refreshCaptcha} />

                          </div>

                          <div className="form-group form-row mt-3 mb-0 text-center">
                            <button
                              className="btn btn-primary btn-block btn_txt"
                              type="submit"
                              onClick={onSubmit}
                            >
                              Login
                            </button>
                          </div>

                          <div className="row checkbox">
                            <Link
                              to="/reset-password"
                              className="col-md-8 btn-link text-capitalize text-right mt-2 sign_up"
                            >
                              Forgot Password ?
                            </Link>
                          </div>

                          <div className="login_links text-center">
                            <div className="mt-2">
                              Don't have an Account ?
                              <Link
                                className="btn-link text-capitalize sign_up ml-2"
                                to="/signup"
                              >
                                Sign Up
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, { login, logoutPrevSession })(LogIn);
