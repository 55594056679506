import React, { useState, useEffect } from "react";
import {
  GridComponent,
  Inject,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import { useHistory } from "react-router-dom";
import { get_molds } from "../../actions/auth";
import { connect } from "react-redux";
import DataService from "../../services/ApiService";
import Loader from "../../assets/Loader";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { template } from "lodash";

const AllProjectStatus = ({ user, get_molds }) => {
  const history = useHistory();

  const BackToDashboard = () => {
    history.push("/dashboard");
  };

  var grid;

  const toolbar = ["Search"];

  const selectionSettings = { type: "Single" };

  const [isLoading, setIsLoading] = useState(true);

  let StoredMoldData = JSON.parse(sessionStorage.getItem("MoldData")) || [];

  const [AllmoldData, setAllmoldData] = useState(StoredMoldData);

  const [GridData, setGridData] = useState([])

  const [SelectedSessionData, setSelectedSessionData] = useState([])

  const [SelectedModuleIndex, setSelectedModuleIndex] = useState()

  const [SessionData, setSessionData] = useState({
    MeltSessions: [],
    PPSSessions: [],
    SixStepSessions: [],
    QualitySessions: [],
    DOESessions: [],
  });

  const [ModalStates, setModalStates] = useState({
    SelectSession: {
      visibility: false,
      Module: "",
    }
  });

  const ToggleModalStates = (ModalKey, Module) => {
    setModalStates({
      ...ModalStates,
      [ModalKey]: {
        visibility: !ModalStates[ModalKey].visibility,
        Module: Module
      },
    });
  };

  const column = [
    {
      field: "Mold_Id",
      headerText: "Mold Number",
      textAlign: "center",
      width: "25%",
    },
    {
      field: "6_Step_Study",
      headerText: "6 Step Study",
      textAlign: "center",
      width: "20%",
    },
    {
      field: "DOE",
      headerText: "DOE",
      textAlign: "center",
      width: "20%",
    },
    {
      field: "PPS",
      headerText: "Plastic Process Sheet",
      textAlign: "center",
      width: "20%",
    },
    {
      field: "Quality_Data",
      headerText: "Quality Data",
      textAlign: "center",
      width: "20%",
    },
    {
      field: "Melt_Prep",
      headerText: "Melt Preparation",
      textAlign: "center",
      width: "20%",
    }
  ];

  function created(args) {
    if (grid) {
      document
        .getElementById(grid.element.id + "_searchbar")
        .addEventListener("keyup", (args) => {
          var gridObj =
            document.getElementById("ManageSessionsGrid").ej2_instances[0];
          gridObj.search(args.target.value);
        });

    }
  }

  const rowSelected = (args) => {

    let SelectedMold = AllmoldData
      .filter((data) => {
        return data.id === args.data.id
      })

    sessionStorage.setItem("SelectedMoldData", JSON.stringify([{
      id: SelectedMold[0].id,
      Mold_Id: SelectedMold[0].Mold_Id,
    }]))

    // args.data.id, parseInt(args.target.getAttribute('aria-colindex'))
    if (parseInt(args.target.getAttribute('aria-colindex')) === 2) {
      let data = SessionData.SixStepSessions.filter((data) => {
        return data.mold === args.data.id
      })

      setSelectedModuleIndex(parseInt(args.target.getAttribute('aria-colindex')))
      setSelectedSessionData(data)
      ToggleModalStates("SelectSession", "6 Step Study")
    }
    if (parseInt(args.target.getAttribute('aria-colindex')) === 3) {
      let data = SessionData.DOESessions.filter((data) => {
        return data.mold === args.data.id
      })
      // console.log(data)
      setSelectedModuleIndex(parseInt(args.target.getAttribute('aria-colindex')))
      setSelectedSessionData(data)
      ToggleModalStates("SelectSession", "DOE")
    }
    if (parseInt(args.target.getAttribute('aria-colindex')) === 4) {
      let data = SessionData.PPSSessions.filter((data) => {
        return data.mold === args.data.id
      })
      // console.log(data)
      setSelectedModuleIndex(parseInt(args.target.getAttribute('aria-colindex')))
      setSelectedSessionData(data)
      ToggleModalStates("SelectSession", 'Plastic Process Sheet')
    }
    if (parseInt(args.target.getAttribute('aria-colindex')) === 5) {
      let data = SessionData.QualitySessions.filter((data) => {
        return data.mold === args.data.id
      })
      // console.log(data)
      setSelectedModuleIndex(parseInt(args.target.getAttribute('aria-colindex')))
      setSelectedSessionData(data)
      ToggleModalStates("SelectSession", "Quality Data")
    }
    if (parseInt(args.target.getAttribute('aria-colindex')) === 6) {
      let data = SessionData.MeltSessions.filter((data) => {
        return data.mold === args.data.id
      })
      // console.log(data)
      setSelectedModuleIndex(parseInt(args.target.getAttribute('aria-colindex')))
      setSelectedSessionData(data)
      ToggleModalStates("SelectSession", "Melt Preparation")
    }
  };

  // Event to Call the POST request API and save the data
  const fetchSesions = (Id) => {
    return new Promise((resolve, reject) => {
      if (Id) {
        // Fetch Melt Sessions
        DataService.FetchMeltSession(Id)
          .then((res) => {
            if (!res.data.message) {
              setSessionData((prevData) => ({
                ...prevData,
                MeltSessions: [
                  ...prevData.MeltSessions,
                  ...res.data
                ],
              }))
            } else {
              setSessionData((prevData) => ({
                ...prevData,
                MeltSessions: [...prevData.MeltSessions],
              }));
            }
          })
          .catch((err) => {
            // console.error(err);
          });

        // Fetch Six Step Sessions
        DataService.FetchSession(Id)
          .then((res) => {
            if (!res.data.message) {
              setSessionData((prevData) => ({
                ...prevData,
                SixStepSessions: [
                  ...prevData.SixStepSessions,
                  ...res.data
                ],
              }));
            } else {
              setSessionData((prevData) => ({
                ...prevData,
                SixStepSessions: [...prevData.SixStepSessions],
              }));
            }
          })
          .catch((err) => {
            // console.error(err);
          });

        DataService.FetchPPSSession(Id)
          .then((res) => {
            if (!res.data.message) {
              setSessionData((prevData) => ({
                ...prevData,
                PPSSessions: [
                  ...prevData.PPSSessions,
                  ...res.data
                ],
              }))
            } else {
              setSessionData((prevData) => ({
                ...prevData,
                PPSSessions: [...prevData.PPSSessions],
              }));
            }
          })
          .catch((err) => {
            // console.error(err);
          });

        DataService.FetchQualitySession(Id)
          .then((res) => {
            if (!res.data.message) {
              setSessionData((prevData) => ({
                ...prevData,
                QualitySessions: [
                  ...prevData.QualitySessions,
                  ...res.data
                ],
              }));
            } else {
              setSessionData((prevData) => ({
                ...prevData,
                QualitySessions: [...prevData.QualitySessions],
              }));
            }
          })
          .catch((err) => {
            // console.error(err);
          });

        DataService.FetchDoeSession(Id)
          .then((res) => {
            if (!res.data.message) {
              setSessionData((prevData) => ({
                ...prevData,
                DOESessions: [
                  ...prevData.DOESessions,
                  ...res.data
                ],
              }));
            } else {
              setSessionData((prevData) => ({
                ...prevData,
                DOESessions: [...prevData.DOESessions],
              }));
            }
          })
          .catch((err) => {
            // console.error(err);
          });
        resolve();
      }
    });
  }

  useEffect(() => {

    if (user) {
      get_molds(user.id)
    }

    // eslint-disable-next-line
  }, [user])

  useEffect(() => {

    for (let i = 0; i < AllmoldData.length; i++) {
      fetchSesions(AllmoldData[i].id)
    }

    // eslint-disable-next-line
  }, [AllmoldData])

  useEffect(() => {

    let TempData = []

    for (let i = 0; i < AllmoldData.length; i++) {

      // Count objects where `active` is `true`
      const SixStepSessionCount = SessionData.SixStepSessions.filter(data => data.mold === AllmoldData[i].id).length;
      const DOESessionCount = SessionData.DOESessions.filter(data => data.mold === AllmoldData[i].id).length;
      const PPSSessionCount = SessionData.PPSSessions.filter(data => data.mold === AllmoldData[i].id).length;
      const QualitySessionCount = SessionData.QualitySessions.filter(data => data.mold === AllmoldData[i].id).length;
      const MeltSessionCount = SessionData.MeltSessions.filter(data => data.mold === AllmoldData[i].id).length;

      TempData.push({
        "id": AllmoldData[i].id,
        "Mold_Id": AllmoldData[i].Mold_Id,
        "6_Step_Study": SixStepSessionCount > 0 ? SixStepSessionCount : "NA",
        "DOE": DOESessionCount > 0 ? DOESessionCount : "NA",
        "PPS": PPSSessionCount > 0 ? PPSSessionCount : "NA",
        "Quality_Data": QualitySessionCount > 0 ? QualitySessionCount : "NA",
        "Melt_Prep": MeltSessionCount > 0 ? MeltSessionCount : "NA"
      })
    }

    // console.log(TempData)

    setGridData(TempData)

    setTimeout(() => {
      setIsLoading(false);
    }, 1500);

  }, [SessionData]);

  // Redirect's to sixStepstudy of that respective session
  const NavigateToModule = (props) => {

    // console.log(props)

    // Using the "btoa" method to encrypt the URL so that the exact ID should not be visible
    var Mold_Id = btoa(props.mold);
    var Session_Id = btoa(props.id);
    var Session_Name = btoa(props.Session_Name);
    var Mold_Name = btoa(Mold_Id.Mold_Id);

    setTimeout(() => {
      if (SelectedModuleIndex === 2) {

        history.push(
          `/sixstepstudy/${Mold_Id}/${Session_Id}/${Mold_Name}/${Session_Name}/sixstepstudy`
        );
      } else if (SelectedModuleIndex === 6) {
        history.push({
          pathname: `${Session_Id}/${Session_Name}/${Mold_Id}/${Mold_Name}/meltpreparation`,
        });
      } else if (SelectedModuleIndex === 4) {

        history.push({
          pathname: `${Session_Id}/${Session_Name}/${Mold_Id}/${Mold_Name}/PPS`,
        });
      } else if (SelectedModuleIndex === 5) {

        history.push({
          pathname: `${Session_Id}/${Session_Name}/${Mold_Id}/${Mold_Name}/qualitydata`
        });
      } else if (SelectedModuleIndex === 3) {

        history.push({
          pathname: `${Session_Id}/DOE/summary`,
          state: { mold: Mold_Id },
        });

      }
    }, 100);

  };

  return (
    <>
      {isLoading ? <Loader /> : <></>}
      <div className="d-flex justify-content-between ml-3 pt-3 pb-3">
        <div className="d-flex">
          <div>
            <span
              className="BreadCrum"
              onClick={BackToDashboard}
              style={{ fontSize: "14px", color: "blue" }}
            >
              {" "}
              Dashboard{" "}
            </span>
          </div>
          <div>
            <span style={{ fontSize: "14px" }}>
              {" "}
              {">"}{" "}
            </span>
          </div>
          <div>
            <span style={{ fontSize: "14px" }}> Mold Activity Details </span>
          </div>
        </div>
      </div>

      <Modal
        isOpen={ModalStates.SelectSession.visibility}
        toggle={() => ToggleModalStates("SelectSession", null)}
        centered={true}
        style={{ width: "500px" }}
      >
        <ModalHeader> {ModalStates.SelectSession.Module} Session Details </ModalHeader>
        <ModalBody>
          <GridComponent
            width={"25vw"}
            height={"30vh"}
            id="SelectedSessionsGrid"
            dataSource={SelectedSessionData}
            columns={[
              {
                field: "Session_Name",
                headerText: "Session Name",
                textAlign: "center",
                width: "20%",
                template: (props) => {
                  return (
                    <span style={{ textDecoration: "underline", cursor: "pointer", color: "blue" }} onClick={() => NavigateToModule(props)}>
                      {props["Session_Name"]}
                    </span>
                  );
                }
              },
              {
                field: "Date",
                headerText: "Created Date",
                textAlign: "center",
                width: "20%",
              }
            ]}
          >
          </GridComponent>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => ToggleModalStates("SelectSession", null)}
          >
            {" "}
            Close{" "}
          </Button>
        </ModalFooter>
      </Modal>

      <div className="ml-4 table-responsive">
        <GridComponent
          rowSelected={rowSelected}
          width={"60vw"}
          height={"60vh"}
          ref={(g) => (grid = g)}
          id="MoldActivutyGrid"
          toolbar={toolbar}
          dataSource={GridData}
          columns={column}
          selectionSettings={selectionSettings}
          created={created.bind(this)}
        >
          <Inject services={[Toolbar]} />
        </GridComponent>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  get_molds,
})(AllProjectStatus);
