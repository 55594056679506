import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import Header2 from "../../common/header-component/header2";
// CSS
import "../../App.css";
import { Table } from "reactstrap";

// To generate random Id
import { nanoid } from "nanoid";
import { useEffect } from "react";
import { connect } from "react-redux";
import DataService from "../../../services/ApiService";

import { useHistory } from "react-router-dom";
import {
  GridComponent,
  Inject,
  Toolbar,
  Page,
} from "@syncfusion/ej2-react-grids";
import ConvertInputFields from "./ConvertInputFields";
import LeftFormsection from "./LeftFormsection";
import RightFormSection from "./RightFormSection";
import BreadCrumb from "../CommonSections/BreadCrumb";

const MachineNew = ({ user }) => {
  var grid;

  const toolbar = ["Search"];

  const history = useHistory();

  const [EditModal, setEditModal] = useState(false);

  const [header, setHeader] = useState({
    header: "",
    key: null,
  });

  const [column, setColumn] = useState([]);

  const [DuplicateValue, setDuplicatetValue] = useState(false);

  const [EmptyAlert, setEmptyAlert] = useState(false);

  const [isColumnId, setIsColumnId] = useState(null);

  const [isDeleteId, setIsDeleteId] = useState(null);

  const [FieldsExists, setFieldsExists] = useState(false);

  const [SamePage, setSamePage] = useState(false);

  // State and Event for the Edit and Add Modal
  const [modal, setModal] = useState();

  const toggleEditHeader = () => {
    setModal(!modal);
  };

  let [SelectedMaterials, setSelectedMaterials] = useState({
    Materials_Indexes: [],
    Materials_Names: [],
    Materials_Ids: [],
  });

  const [Max_Screw_Rotation_Linear_Speed, setMax_Screw_Rotation_Linear_Speed] =
    useState("");

  const [machineData, setMachineData] = useState({
    Machine_Number: "",
    Make: "",
    "Type(Platen_Orientation)": "Horizontal",
    Tonnage: "",
    Screw_Diameter: "",
    Max_Screw_Rotation_Speed: "",
    Max_Screw_Rotation_Linear_Speed: Max_Screw_Rotation_Linear_Speed === "" ? "" : Max_Screw_Rotation_Linear_Speed,
    Max_Machine_Pressure: "",
    Max_Injection_Speed: "",
    Intensification_Ratio: "",
    Max_Plastic_Pressure: "",
    "Max_shot_Capacity(Wt)": "",
    Max_Melt_Temperature: "",
    Min_allowable_Mold_Stack_Height: "",
    Max_allowable_Mold_Stack_Height: "",
    Max_Mold_Open_Daylight: "",
    "Tiebar_Clearance-Width": "",
    Max_Mold_Vertical_Height: "",
    Max_Mold_Width: "",
    Number_of_Core_Pulls: "",
  });

  const [machineUnitData, setmachineUnitData] = useState({
    Machine_Number: {
      value: "",
      unit_id: "",
    },
    Make: {
      value: "",
      unit_id: "",
    },
    Number_of_Core_Pulls: {
      value: "",
      unit_id: "",
    },
    Intensification_Ratio: {
      value: "",
      unit_id: "",
    },
    "Type(Platen_Orientation)": {
      value: "Horizontal",
      unit_id: null,
    },
    Tonnage: {
      value: "",
      unit_id: "",
    },
    Max_Mold_Width: {
      value: "",
      unit_id: "",
    },
    Screw_Diameter: {
      value: "",
      unit_id: "",
    },
    Max_Injection_Speed: {
      value: "",
      unit_id: "",
    },
    Max_Machine_Pressure: {
      value: "",
      unit_id: "",
    },
    Max_Melt_Temperature: {
      value: "",
      unit_id: "",
    },
    Max_Plastic_Pressure: {
      value: "",
      unit_id: "",
    },
    "Max_shot_Capacity(Wt)": {
      value: "",
      unit_id: "",
    },
    Max_Mold_Open_Daylight: {
      value: "",
      unit_id: "",
    },
    "Tiebar_Clearance-Width": {
      value: "",
      unit_id: "",
    },
    Max_Mold_Vertical_Height: {
      value: "",
      unit_id: "",
    },
    Max_Screw_Rotation_Speed: {
      value: "",
      unit_id: "",
    },
    Max_allowable_Mold_Stack_Height: {
      value: "",
      unit_id: "",
    },
    Min_allowable_Mold_Stack_Height: {
      value: "",
      unit_id: "",
    },
    Max_Screw_Rotation_Linear_Speed: {
      value: "",
      unit_id: "",
    },
    Selected_Materials: SelectedMaterials,
  });

  const [UnitSettings, setUnitSettings] = useState({
    TemperatureUnit: {},
    DistanceUnit: {},
    ScrewDistanceUnit: {},
    PressureUnit: {},
    SpeedUnit: {},
    LinearSpeedUnit: {},
    WeightUnit: {},
    TonnageUnit: {},
  });

  let StoredUnits = JSON.parse(sessionStorage.getItem("StoredUnits")) || [];
  let SelectedUnits = JSON.parse(sessionStorage.getItem("SelectedUnits")) || [];
  let MaterialData = JSON.parse(sessionStorage.getItem("MaterialData")) || [];
  let StoredMachineData =
    JSON.parse(sessionStorage.getItem("MachineData")) || [];

  const [UserSelectedUnits, setUserSelectedUnits] = useState([]);
  const [BaseUnits, setBaseUnits] = useState([]);
  const [BaseUnitsArray, setBaseUnitsArray] = useState([]);
  const [SelectedUnitsArray, setSelectedUnitsArray] = useState([]);

  const [SelectedPart, setSelectedPart] = useState("Tonnage");
  const [UnitsForConversion, setUnitsForConversion] = useState({
    FromUnit: "",
    ToUnit: "",
  });

  const selectionSettings = { type: "single" };

  const rowSelected = (args) => {
    // console.log(args);

    if (args.data.length > 1) {
      const newSelectedMaterials = new Set();
      const newSelectedMaterialsIndex = new Set();
      const newSelectedMaterialsIds = new Set();

      for (let i = 0; i < args.rowIndexes.length; i++) {
        newSelectedMaterialsIds.add(args.data[i].id);
        newSelectedMaterials.add(args.data[i].Material_Id);
        newSelectedMaterialsIndex.add(grid.getSelectedRowIndexes()[i]);

        // console.log(grid.getSelectedRowIndexes()[i]);
      }

      setSelectedMaterials({
        Materials_Indexes: [...newSelectedMaterialsIndex],
        Materials_Names: [...newSelectedMaterials],
        Materials_Ids: [...newSelectedMaterialsIds],
      });
    } else {
      setSelectedMaterials({
        Materials_Indexes: grid.getSelectedRowIndexes(),
        Materials_Names: [
          ...SelectedMaterials.Materials_Names,
          args.data.Material_Id,
        ],
        Materials_Ids: [...SelectedMaterials.Materials_Ids, args.data.id],
      });

      // console.log(newSelectedMaterialsIndex)
    }
  };

  const rowDeSelected = (args) => {
    // console.log(args);

    // console.log(grid.getSelectedRowIndexes());

    let updatedMaterialsNames = [...SelectedMaterials.Materials_Names];
    let updatedMaterialsIndexes = [...SelectedMaterials.Materials_Indexes];
    let updatedMaterialsIds = [...SelectedMaterials.Materials_Ids];

    if (args.data.length > 1) {
      for (let i = 0; i < args.data.length; i++) {
        updatedMaterialsNames = [];
        updatedMaterialsIndexes = [];
        updatedMaterialsIds = [];
      }
    } else {
      const indexToRemove = args.data.Material_Id;
      updatedMaterialsNames = updatedMaterialsNames.filter(
        (name, index) => name !== indexToRemove
      );
      updatedMaterialsIds = updatedMaterialsIds.filter(
        (id, index) => id !== args.data.id
      );
    }

    setSelectedMaterials({
      Materials_Names: updatedMaterialsNames,
      Materials_Ids: updatedMaterialsIds,
    });
  };

  const rowClick = () => {
    setSelectedMaterials({
      ...SelectedMaterials,
      Materials_Indexes: grid.getSelectedRowIndexes(),
    });
  };

  useEffect(() => {
    // console.log(StoredMachineData);
  }, [StoredMachineData]);

  const onGridReady = () => {
    if (grid) {
      const gridInstance = grid;
      const defaultSelectedRowIndexes = SelectedMaterials.Materials_Indexes;
      gridInstance.selectRows(defaultSelectedRowIndexes);
    }
  };

  const [ModalStates, setModalStates] = useState({
    MachineIdConfirm: {
      visibility: false,
      message: "Machine Id is mandatory.",
    },
    DeleteConfirm: {
      visibility: false,
      message:
        "The field may have data associated with it. Do you want to continue deleting the same ?",
    },
    MachineIdUnique: {
      visibility: false,
      message: "Machine Id should be unique. This Machine Id already exists.",
    },
    ConvertConfirm: {
      visibility: false,
      message:
        "You are changing the units, do you want to change the value accordingly ?",
    },
    MinMaxStackHeight: {
      visibility: false,
      message:
        "Min allowable Mold Stack Height should be less than the Max allowable Mold Stack Height.",
    },
  });

  const ToggleModalStates = (ModalKey) => {
    setModalStates({
      ...ModalStates,
      [ModalKey]: {
        ...ModalStates[ModalKey],
        visibility: !ModalStates[ModalKey].visibility,
      },
    });
  };

  const OpenDialog = () => {
    const dialogBox = document.getElementById("dialogBox");
    const dialogBoxContainer = document.getElementById("dialogBoxContainer");

    dialogBox.classList.remove("hidden");
    dialogBoxContainer.classList.add("backdrop");
  };

  const closeDialog = () => {
    const dialogBox = document.getElementById("dialogBox");
    const dialogBoxContainer = document.getElementById("dialogBoxContainer");

    dialogBox.classList.add("hidden");
    dialogBoxContainer.classList.remove("backdrop");
  };

  const handleChange = (event) => {
    const { name, value, dataset } = event.target;

    setMachineData({
      ...machineData,
      [name]: value,
    });

    let data = {
      value: value,
      unit_id: dataset.unit ? dataset.unit : null,
    };

    setmachineUnitData({
      ...machineUnitData,
      [name]: {
        ...machineUnitData[name],
        ...data,
      },
    });
  };

  useEffect(() => {

    // console.log('v', Max_Screw_Rotation_Linear_Speed)

  }, [machineData])

  const handleDropDownChange = (event) => {
    const { name, value, dataset } = event.target;

    setSelectedPart(dataset.category);

    setUnitsForConversion({
      FromUnit: UnitSettings[`${dataset.category}Unit`].unit_id,
      ToUnit: parseInt(value),
    });

    ToggleModalStates("ConvertConfirm");

    setUnitSettings({
      ...UnitSettings,
      [name]: {
        unit_name: BaseUnits.find((unit) => unit.unit_id === parseInt(value))
          .unit_name,
        unit_id: BaseUnits.find((unit) => unit.unit_id === parseInt(value))
          .unit_id,
        decimals: SelectedUnitsArray.find(
          (unit) => unit.unit_id === parseInt(value)
        )
          ? SelectedUnitsArray.find((unit) => unit.unit_id === parseInt(value))
            .decimals
          : BaseUnits.find((unit) => unit.unit_id === parseInt(value)).decimals,
      },
    });

    let data = {
      value: machineData[dataset.fieldname],
      unit_id: value ? value : null,
    };

    setmachineUnitData({
      ...machineUnitData,
      [dataset.fieldname]: data,
    });

    // console.log({
    //   ...machineUnitData,
    //   [dataset.fieldname]: data,
    // });
  };

  const UpdateInputConversions = () => {
    let UpdatedValues = ConvertInputFields({
      UnitSettings,
      SelectedPart,
      UnitsForConversion,
      machineData,
      machineUnitData,
    });

    setMachineData({
      ...machineData,
      ...UpdatedValues[0],
    });

    setmachineUnitData({
      ...machineUnitData,
      ...UpdatedValues[1],
    });

    // console.log(UpdatedValues[1]);

    ToggleModalStates("ConvertConfirm");

    setUnitsForConversion({
      FromUnit: "",
      ToUnit: "",
    });
  };

  const UpdateUnitsAndClose = () => {
    ToggleModalStates("ConvertConfirm");

    setmachineUnitData({
      ...machineUnitData,
      Min_allowable_Mold_Stack_Height: {
        ...machineUnitData.Min_allowable_Mold_Stack_Height,
        unit_id: UnitsForConversion.ToUnit,
      },

      Max_allowable_Mold_Stack_Height: {
        ...machineUnitData["Max_allowable_Mold_Stack_Height"],
        unit_id: UnitsForConversion.ToUnit,
      },

      Max_Mold_Open_Daylight: {
        ...machineUnitData["Max_Mold_Open_Daylight"],
        unit_id: UnitsForConversion.ToUnit,
      },

      "Tiebar_Clearance-Width": {
        ...machineUnitData["Tiebar_Clearance-Width"],
        unit_id: UnitsForConversion.ToUnit,
      },

      Max_Mold_Vertical_Height: {
        ...machineUnitData["Max_Mold_Vertical_Height"],
        unit_id: UnitsForConversion.ToUnit,
      },

      Max_Mold_Width: {
        ...machineUnitData["Max_Mold_Width"],
        unit_id: UnitsForConversion.ToUnit,
      },
    });
  };

  useEffect(() => {
    setMachineData({
      ...machineData,
      Max_Screw_Rotation_Linear_Speed:
        machineUnitData?.Screw_Diameter?.unit_id === 11 &&
          !isNaN(parseFloat(machineUnitData?.Screw_Diameter?.value)) &&
          !isNaN(parseFloat(machineUnitData?.Max_Screw_Rotation_Speed?.value))
          ? (
            (3.14 *
              parseFloat(machineUnitData.Screw_Diameter.value) *
              parseFloat(machineUnitData.Max_Screw_Rotation_Speed.value)) /
            60
          ).toFixed(2)
          : (
            (3.14 *
              (parseFloat(machineUnitData.Screw_Diameter.value) / 25.4) *
              parseFloat(machineUnitData.Max_Screw_Rotation_Speed.value)) /
            60
          ).toFixed(2),
      Max_Plastic_Pressure: isNaN(
        parseFloat(machineUnitData?.Max_Machine_Pressure.value) *
        parseFloat(machineUnitData?.Intensification_Ratio.value)
      )
        ? ""
        : parseFloat(machineUnitData?.Max_Machine_Pressure.value) *
        parseFloat(machineUnitData?.Intensification_Ratio.value),
    });
    // console.log(machineData);
    // console.log(machineUnitData);
  }, [machineUnitData]);

  const SaveData = () => {
    machineUnitData.Selected_Materials = SelectedMaterials;

    if (machineData.Max_Screw_Rotation_Linear_Speed === "NaN") { machineData.Max_Screw_Rotation_Linear_Speed = "" } else { machineData.Max_Screw_Rotation_Linear_Speed = Max_Screw_Rotation_Linear_Speed }

    // console.log(Max_Screw_Rotation_Linear_Speed)

    let PostData = {
      user: user.id,
      Machine_Data: machineData,
      Units: machineUnitData,
    };

    // console.log(Max_Screw_Rotation_Linear_Speed, PostData.Machine_Data)

    let ColumnData;

    DataService.SaveMachineData(PostData).then((res) => {
      // console.log(res.data);

      let RowId = btoa(parseInt(res.data.Machine_Id));

      // console.log(res.data.Material_Id)

      if (SamePage) {
        history.push({
          pathname: `/database/Options/${RowId}/MachineView`,
        });
      } else {
        history.push({
          pathname: "/database/Database",
          TabIdx: 2,
        });
      }
    });

    if (!FieldsExists) {
      ColumnData = {
        user: user.id,
        field_to_be_updated: "Machine",
        Material_Custom_Fields: [],
        Mold_Custom_Fields: [],
        Machine_Custom_Fields: column,
      };

      // console.log(ColumnData);

      DataService.SaveFieldsData(ColumnData).then((res) => { });
    } else {
      ColumnData = {
        user: user.id,
        field_to_be_updated: "Machine",
        Material_Custom_Fields: [],
        Mold_Custom_Fields: [],
        Machine_Custom_Fields: column,
      };

      // console.log(ColumnData);

      DataService.UpdateFieldsData(user.id, ColumnData).then((res) => { });
    }
  };

  const CheckForMachineId = () => {
    return new Promise((resolve, reject) => {
      let allFieldsFilled = true;

      // Check each field of each material object in the array
      if (!machineData["Machine_Number"]) {
        allFieldsFilled = false;
      }

      if (allFieldsFilled) {
        resolve();
      } else {
        ToggleModalStates("MachineIdConfirm");
      }
    });
  };

  const CheckForDuplicateMachineId = () => {
    return new Promise((resolve, reject) => {
      let MachineIdExists;

      if (isNaN(machineData["Machine_Number"])) {
        MachineIdExists = StoredMachineData.some(
          (machine) =>
            String(machine.Machine_Id).toLowerCase() ===
            String(machineData["Machine_Number"]).toLowerCase()
        );
      } else {
        MachineIdExists = StoredMachineData.some(
          (machine) => machine.Machine_Id === machineData["Machine_Number"]
        );
      }

      if (!MachineIdExists) {
        resolve();
      } else {
        ToggleModalStates("MachineIdUnique");
      }

      // console.log(MaterialIdExists)
    });
  };

  const CheckForMixMaxStackHeight = () => {
    return new Promise((resolve, reject) => {
      if (
        parseFloat(machineUnitData.Min_allowable_Mold_Stack_Height.value) >
        parseFloat(machineUnitData.Max_allowable_Mold_Stack_Height.value)
      ) {
        ToggleModalStates("MinMaxStackHeight");
      } else {
        resolve();
      }
    });
  };

  const SubmitData = () => {
    CheckForMixMaxStackHeight().then(() => {
      CheckForMachineId().then(() => {
        CheckForDuplicateMachineId().then(() => {
          SaveData();
        });
      });
    });
  };

  const ToggleEditModal = () => {
    setEditModal(!EditModal);

    setHeader({
      header: "",
      key: null,
    });

    setEmptyAlert(false);

    setDuplicatetValue(false);
  };

  const addHeader = (e) => {
    e.preventDefault();

    setHeader({
      header: e.target.value,
      key: column.length > 0 ? column[column.length - 1].field.slice(5) : 0,
    });

    // console.log(e.target.value);

    setEmptyAlert(false);

    setDuplicatetValue(false);
  };

  const CheckForHeaderName = () => {
    return new Promise((resolve, reject) => {
      let HeaderExists;

      // console.log(column);

      if (column.length > 0) {
        HeaderExists = column.some(
          (headers) =>
            headers.headerText?.toLowerCase() === header.header.toLowerCase()
        );
      }

      resolve(HeaderExists);
    });
  };

  const addColumn = () => {
    if (!header.header) {
      setHeader({
        header: "",
        key: null,
      });

      setEmptyAlert(!EmptyAlert);
    } else {
      CheckForHeaderName().then((HeaderExists) => {
        if (!HeaderExists) {
          const newColumn = {
            id: nanoid(),
            field: `value${parseInt(header.key) + 1}`,
            headerText: header.header,
            width: "100"
          };

          setColumn([...column, newColumn]);

          setHeader({
            header: "",
            key: null,
          });

          setEmptyAlert(false);

          setDuplicatetValue(false);

          // console.log(column);
        } else {
          setDuplicatetValue(!DuplicateValue);

          setHeader({
            header: "",
            key: null,
          });
        }
      });
    }
  };

  useEffect(() => {
    // console.log(header)
  }, [header]);

  const editColumn = (data, key) => {
    setIsColumnId({
      data: data,
      key: key,
    });

    // console.log(data);
  };

  const DeleteColumnId = (HeaderData) => {
    setIsDeleteId(HeaderData);
  };

  const editColumnHeader = () => {
    if (header) {
      // console.log(header);

      setColumn(
        column.map((element) => {
          if (element.id === isColumnId.data.id) {
            return { ...element, headerText: header.header };
          }
          return element;
        })
      );

      setHeader({
        header: "",
        key: null,
      });

      setIsColumnId(null);
    } else {
    }
  };

  const deleteColumn = () => {
    setColumn((prevArray) => {
      const newArray = prevArray.filter((HeaderData) => {
        return HeaderData.id != isDeleteId.id;
      });

      return newArray;
    });

    let TempArray = [machineData];

    const newArray = TempArray.some((obj) =>
      obj.hasOwnProperty(isDeleteId.headerText)
    );

    if (newArray) {
      delete machineData[isDeleteId.field];

      setIsDeleteId(null);

      // console.log(materialData);
    }

    ToggleModalStates("DeleteConfirm");
  };

  const GetData = (id) => {
    DataService.GetFieldsData(id).then((res) => {
      if (res.data.length > 0) {
        if (res.data[0].Machine_Custom_Fields) {
          // console.log(res.data[0].Material_Custom_Fields);

          setColumn(res.data[0].Machine_Custom_Fields);

          setFieldsExists(true);
        }
      }
    });
  };

  useEffect(() => {
    if (user) {
      GetData(user.id);

      let allUnits = [],
        selectedUnits = [];

      // Iterate through each category in the data
      for (const category in StoredUnits.Unit_Data[0]) {
        // Add each unit object to the allUnits array
        allUnits = allUnits.concat(StoredUnits.Unit_Data[0][category]);
      }

      // Iterate through each category in the data
      for (const category in SelectedUnits.Unit_Data[0]) {
        // Add each unit object to the allUnits array
        selectedUnits = selectedUnits.concat(
          SelectedUnits.Unit_Data[0][category]
        );
      }

      setSelectedUnitsArray(selectedUnits);
      setUserSelectedUnits(SelectedUnits.Unit_Data[0]);

      setBaseUnits(allUnits);
      setBaseUnitsArray(StoredUnits.Unit_Data[0]);
    }
  }, [user]);

  useEffect(() => {
    setUnitSettings({
      ...UnitSettings,

      TonnageUnit: {
        unit_id: BaseUnitsArray.Tonnage
          ? BaseUnitsArray.Tonnage[0].unit_id
          : "",
        unit_name: BaseUnitsArray.Tonnage
          ? BaseUnitsArray.Tonnage[0].unit_name
          : "",
        decimals: 0.12,
      },

      SpeedUnit: {
        unit_id: UserSelectedUnits.Speed
          ? BaseUnits.find(
            (unit) => unit.unit_id === UserSelectedUnits.Speed.unit_id
          ).unit_id
          : "",
        unit_name: UserSelectedUnits.Speed
          ? BaseUnits.find(
            (unit) => unit.unit_id === UserSelectedUnits.Speed.unit_id
          ).unit_name
          : "",
        decimals: UserSelectedUnits.Speed
          ? BaseUnits.find(
            (unit) => unit.unit_id === UserSelectedUnits.Speed.unit_id
          ).decimals
          : "",
      },

      LinearSpeedUnit: {
        unit_id: UserSelectedUnits.Speed
          ? BaseUnits.find(
            (unit) => unit.unit_id === UserSelectedUnits.Speed.unit_id
          ).unit_id
          : "",
        unit_name: UserSelectedUnits.Speed
          ? BaseUnits.find(
            (unit) => unit.unit_id === UserSelectedUnits.Speed.unit_id
          ).unit_name
          : "",
        decimals: UserSelectedUnits.Speed
          ? BaseUnits.find(
            (unit) => unit.unit_id === UserSelectedUnits.Speed.unit_id
          ).decimals
          : "",
      },

      TemperatureUnit: {
        unit_id: UserSelectedUnits.Temperature
          ? BaseUnits.find(
            (unit) => unit.unit_id === UserSelectedUnits.Temperature.unit_id
          ).unit_id
          : "",
        unit_name: UserSelectedUnits.Temperature
          ? BaseUnits.find(
            (unit) => unit.unit_id === UserSelectedUnits.Temperature.unit_id
          ).unit_name
          : "",
        decimals: UserSelectedUnits.Temperature
          ? BaseUnits.find(
            (unit) => unit.unit_id === UserSelectedUnits.Temperature.unit_id
          ).decimals
          : "",
      },

      DistanceUnit: {
        unit_id: UserSelectedUnits.Distance
          ? BaseUnits.find(
            (unit) => unit.unit_id === UserSelectedUnits.Distance.unit_id
          ).unit_id
          : "",
        unit_name: UserSelectedUnits.Distance
          ? BaseUnits.find(
            (unit) => unit.unit_id === UserSelectedUnits.Distance.unit_id
          ).unit_name
          : "",
        decimals: UserSelectedUnits.Distance
          ? BaseUnits.find(
            (unit) => unit.unit_id === UserSelectedUnits.Distance.unit_id
          ).decimals
          : "",
      },

      ScrewDistanceUnit: {
        unit_id: UserSelectedUnits.Distance
          ? BaseUnits.find(
            (unit) => unit.unit_id === UserSelectedUnits.Distance.unit_id
          ).unit_id
          : "",
        unit_name: UserSelectedUnits.Distance
          ? BaseUnits.find(
            (unit) => unit.unit_id === UserSelectedUnits.Distance.unit_id
          ).unit_name
          : "",
        decimals: UserSelectedUnits.Distance
          ? BaseUnits.find(
            (unit) => unit.unit_id === UserSelectedUnits.Distance.unit_id
          ).decimals
          : "",
      },

      PressureUnit: {
        unit_id: UserSelectedUnits.Pressure
          ? BaseUnits.find(
            (unit) => unit.unit_id === UserSelectedUnits.Pressure.unit_id
          ).unit_id
          : "",
        unit_name: UserSelectedUnits.Pressure
          ? BaseUnits.find(
            (unit) => unit.unit_id === UserSelectedUnits.Pressure.unit_id
          ).unit_name
          : "",
        decimals: UserSelectedUnits.Pressure
          ? BaseUnits.find(
            (unit) => unit.unit_id === UserSelectedUnits.Pressure.unit_id
          ).decimals
          : "",
      },

      WeightUnit: {
        unit_id: UserSelectedUnits.Weight
          ? BaseUnits.find(
            (unit) => unit.unit_id === UserSelectedUnits.Weight.unit_id
          ).unit_id
          : "",
        unit_name: UserSelectedUnits.Weight
          ? BaseUnits.find(
            (unit) => unit.unit_id === UserSelectedUnits.Weight.unit_id
          ).unit_name
          : "",
        decimals: UserSelectedUnits.Weight
          ? BaseUnits.find(
            (unit) => unit.unit_id === UserSelectedUnits.Weight.unit_id
          ).decimals
          : "",
      },
    });

    setmachineUnitData({
      ...machineUnitData,

      Tonnage: {
        value: "",
        unit_id: BaseUnitsArray.Tonnage
          ? BaseUnitsArray.Tonnage[0].unit_id
          : "",
      },
      Screw_Diameter: {
        value: "",
        unit_id: UserSelectedUnits.Distance
          ? BaseUnits.find(
            (unit) => unit.unit_id === UserSelectedUnits.Distance.unit_id
          ).unit_id
          : "",
      },
      Max_Screw_Rotation_Speed: {
        value: "",
        unit_id: BaseUnitsArray.Speed ? BaseUnitsArray?.Speed[2].unit_id : 0,
      },
      Max_Screw_Rotation_Linear_Speed: {
        value: "",
        unit_id: UserSelectedUnits.Speed
          ? BaseUnits.find(
            (unit) => unit.unit_id === UserSelectedUnits.Speed.unit_id
          ).unit_id
          : "",
      },
      Max_Machine_Pressure: {
        value: "",
        unit_id: UserSelectedUnits.Pressure
          ? BaseUnits.find(
            (unit) => unit.unit_id === UserSelectedUnits.Pressure.unit_id
          ).unit_id
          : "",
      },
      Max_Injection_Speed: {
        value: "",
        unit_id: UserSelectedUnits.Speed
          ? BaseUnits.find(
            (unit) => unit.unit_id === UserSelectedUnits.Speed.unit_id
          ).unit_id
          : "",
      },
      Max_Plastic_Pressure: {
        value: "",
        unit_id: UserSelectedUnits.Pressure
          ? BaseUnits.find(
            (unit) => unit.unit_id === UserSelectedUnits.Pressure.unit_id
          ).unit_id
          : "",
      },
      "Max_shot_Capacity(Wt)": {
        value: "",
        unit_id: UserSelectedUnits.Weight
          ? BaseUnits.find(
            (unit) => unit.unit_id === UserSelectedUnits.Weight.unit_id
          ).unit_id
          : "",
      },
      Max_Melt_Temperature: {
        value: "",
        unit_id: UserSelectedUnits.Temperature
          ? BaseUnits.find(
            (unit) => unit.unit_id === UserSelectedUnits.Temperature.unit_id
          ).unit_id
          : "",
      },

      // ***********************************
      Min_allowable_Mold_Stack_Height: {
        value: "",
        unit_id: UserSelectedUnits.Distance
          ? BaseUnits.find(
            (unit) => unit.unit_id === UserSelectedUnits.Distance.unit_id
          ).unit_id
          : "",
      },
      Max_allowable_Mold_Stack_Height: {
        value: "",
        unit_id: UserSelectedUnits.Distance
          ? BaseUnits.find(
            (unit) => unit.unit_id === UserSelectedUnits.Distance.unit_id
          ).unit_id
          : "",
      },
      Max_Mold_Open_Daylight: {
        value: "",
        unit_id: UserSelectedUnits.Distance
          ? BaseUnits.find(
            (unit) => unit.unit_id === UserSelectedUnits.Distance.unit_id
          ).unit_id
          : "",
      },
      "Tiebar_Clearance-Width": {
        value: "",
        unit_id: UserSelectedUnits.Distance
          ? BaseUnits.find(
            (unit) => unit.unit_id === UserSelectedUnits.Distance.unit_id
          ).unit_id
          : "",
      },
      Max_Mold_Vertical_Height: {
        value: "",
        unit_id: UserSelectedUnits.Distance
          ? BaseUnits.find(
            (unit) => unit.unit_id === UserSelectedUnits.Distance.unit_id
          ).unit_id
          : "",
      },
      Max_Mold_Width: {
        value: "",
        unit_id: UserSelectedUnits.Distance
          ? BaseUnits.find(
            (unit) => unit.unit_id === UserSelectedUnits.Distance.unit_id
          ).unit_id
          : "",
      },
    });

    // console.log(BaseUnitsArray);

    // eslint-disable-next-line
  }, [UserSelectedUnits, BaseUnitsArray, BaseUnits]);

  return (
    <>
      <div id="dialogBoxContainer" className="">
        <div id="dialogBox" className="hidden">
          <h5> Machine-Material </h5>
          <hr></hr>
          <div className="dialog-content">
            <span className="ml-2">
              {" "}
              Select Materials that can run in this machine.{" "}
            </span>
            <div
              className="m-2"
              style={{ border: "1px solid black" }}
              onClick={rowClick}
            >
              <GridComponent
                ref={(g) => (grid = g)}
                id="grid2"
                toolbar={toolbar}
                dataSource={MaterialData}
                // allowPaging={true}
                // pageSettings={{ pageSize: 10 }}
                rowSelected={rowSelected.bind(this)}
                rowDeselected={rowDeSelected.bind(this)}
                selectionSettings={selectionSettings}
                dataBound={onGridReady}
                columns={[
                  {
                    headerText: "Select",
                    width: 20,
                    isPrimaryKey: true,
                    textAlign: "left",
                    type: "checkbox",
                  },
                  {
                    field: "Material_Id",
                    headerText: "Material Id",
                    textAlign: "left",
                    width: 75,
                  },
                ]}
                height={200}
              >
                <Inject services={[Toolbar]} />
              </GridComponent>
            </div>
            <div className="d-flex col-md-12">
              <div className="pt-2 pb-2">
                <button
                  className="btn btn-secondary btn-air-secondary mr-2"
                  onClick={closeDialog}
                >
                  Save
                </button>
              </div>

              <div className="pt-2 pb-2 text-left">
                <button
                  className="btn btn-warning btn-air-warning mr-2"
                  onClick={closeDialog}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={ModalStates.MinMaxStackHeight.visibility}
        centered={true}
        style={{ width: "500" }}
      >
        <ModalHeader> Nautilus </ModalHeader>
        <ModalBody>
          <span> {ModalStates.MinMaxStackHeight.message} </span>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => ToggleModalStates("MinMaxStackHeight")}
          >
            {" "}
            Close{" "}
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={ModalStates.MachineIdConfirm.visibility}
        centered={true}
        style={{ width: "500" }}
      >
        <ModalHeader> Nautilus </ModalHeader>
        <ModalBody>
          <span> {ModalStates.MachineIdConfirm.message} </span>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => ToggleModalStates("MachineIdConfirm")}
          >
            {" "}
            Close{" "}
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={ModalStates.DeleteConfirm.visibility}
        centered={true}
        style={{ width: "400px" }}
      >
        <ModalHeader> Nautilus </ModalHeader>
        <ModalBody>
          <span> {ModalStates.DeleteConfirm.message} </span>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={deleteColumn}>
            {" "}
            Delete{" "}
          </Button>
          <Button
            color="primary"
            onClick={() => ToggleModalStates("DeleteConfirm")}
          >
            {" "}
            Close{" "}
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={ModalStates.MachineIdUnique.visibility}
        centered={true}
        style={{ width: "600px" }}
      >
        <ModalHeader> Nautilus </ModalHeader>
        <ModalBody>
          <span> {ModalStates.MachineIdUnique.message} </span>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => ToggleModalStates("MachineIdUnique")}
          >
            {" "}
            Close{" "}
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={ModalStates.ConvertConfirm.visibility}
        centered={true}
        style={{ width: "600px" }}
      >
        <ModalHeader> Nautilus </ModalHeader>
        <ModalBody>
          <span> {ModalStates.ConvertConfirm.message} </span>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={UpdateInputConversions}>
            {" "}
            Yes{" "}
          </Button>
          <Button color="primary" onClick={UpdateUnitsAndClose}>
            {" "}
            No{" "}
          </Button>
        </ModalFooter>
      </Modal>

      <Header2 Title="Database" />

      <div className="container-fluid">
        <div className="d-flex justify-content-between ml-3 pt-3 pb-3">
          <BreadCrumb DB_Name={"Machine"} Current_Page={"New"} TabIdx={2} />
        </div>

        <div className="container-fluid">
          <div className="card p-3  ml-2">
            <div className="d-flex col-md-12">
              <div className="pt-2 pb-2">
                <button
                  className="btn btn-secondary btn-air-secondary mr-2"
                  onClick={SubmitData}
                >
                  Save
                </button>
              </div>

              <div className="pt-2 pb-2 text-left">
                <button
                  className="btn btn-warning btn-air-warning mr-2"
                  onClick={() => {
                    history.push({
                      pathname: "/database/Database",
                      TabIdx: 2,
                    });
                  }}
                >
                  Cancel
                </button>
              </div>
              <div className="pt-2 pb-2 text-left ml-3">
                <div className="form-group m-t-8 m-checkbox-inline mb-0 custom-check-ml pt-2">
                  {/* <div className="checkbox-primary"> */}
                  <input
                    id="checkbox-primary-1"
                    className="checkbox checkbox-primary"
                    type="checkbox"
                    onClick={() => setSamePage(!SamePage)}
                  />
                  <label
                    className="mb-0 mt-1 ml-1"
                    htmlFor="checkbox-primary-1"
                  >
                    <span className="digits"> {"Stay on same page"} </span>
                  </label>
                  {/* </div> */}
                </div>
              </div>
            </div>

            <div className="d-flex col-md-12">
              <div className="col-md-6">
                <div className="form-inline">
                  <LeftFormsection
                    SelectedMachinesUnitData={machineUnitData}
                    handleChange={handleChange}
                    handleDropDownChange={handleDropDownChange}
                    UnitSettings={UnitSettings}
                    BaseUnitsArray={BaseUnitsArray}
                    StoredUnits={BaseUnits}
                    Page={"New"}
                    setMax_Screw_Rotation_Linear_Speed={
                      setMax_Screw_Rotation_Linear_Speed
                    }
                  />
                </div>
              </div>

              <div className="ml-1  col-md-6">
                <div className="form-inline">
                  <RightFormSection
                    SelectedMachinesUnitData={machineUnitData}
                    handleChange={handleChange}
                    handleDropDownChange={handleDropDownChange}
                    UnitSettings={UnitSettings}
                    BaseUnitsArray={BaseUnitsArray}
                    StoredUnits={BaseUnits}
                    Page={"New"}
                  />
                </div>
              </div>
            </div>

            <div className="d-flex col-md-12">
              <div className="ml-5 mt-3 col-md-4">
                <button
                  className="btn btn-secondary btn-air-secondary mr-2"
                  onClick={ToggleEditModal}
                >
                  Edit
                </button>

                <div
                  className="d-flex justify-content-between p-2 mt-3 b-l-6 b-r-6"
                  style={{ background: "#ebebeb" }}
                >
                  <div style={{ width: "60%" }}>
                    Material(s) :
                    <input
                      className="form-control b-b-primary"
                      defaultValue={SelectedMaterials.Materials_Names}
                      readOnly
                    />
                  </div>
                  <div>
                    <button
                      className="btn btn-primary btn-air-primary mt-3"
                      onClick={OpenDialog}
                    >
                      Select
                    </button>
                  </div>
                </div>
              </div>

              <div className="ml-3 col-md-6" id="cusFieldT">
                <div className="row mt-3">
                  <label> Custom Fields </label>
                </div>

                <div style={{ border: "1px solid black", width: "255px" }}>
                  <Table
                    id="Custom_Fields_Table"
                    className="table-responsive"
                    width={300}
                    height={200}
                    cellPadding={0}
                    cellSpacing={0}
                  >
                    <thead>
                      <tr>
                        <th align="center" style={{ width: "160px" }}>
                          Name
                        </th>

                        <th align="center" style={{ width: "160px" }}>
                          Value
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {column.map((HeaderName, HeaderKey) => (
                        <tr key={HeaderKey}>
                          <td align="left" style={{ background: "#fff" }}>
                            <input
                              type="text"
                              className="form-control"
                              style={{ width: "125px" }}
                              value={HeaderName.headerText}
                              readOnly
                            />{" "}
                          </td>

                          <td align="left" style={{ background: "#fff" }}>
                            <input
                              type="text"
                              className="form-control"
                              onChange={handleChange}
                              name={`value${HeaderKey + 1}`}
                              style={{ width: "125px" }}
                              autoComplete="off"
                              onKeyPress={(event) => {
                                if (!/[0.0-9.0]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />{" "}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <Modal isOpen={EditModal} centered={true} style={{ width: "500" }}>
          <ModalHeader> Add Custom Fields </ModalHeader>
          <ModalBody>
            {EmptyAlert ? (
              <span style={{ color: "red" }}> *Please enter field name </span>
            ) : (
              ""
            )}
            {DuplicateValue ? (
              <span style={{ color: "red" }}> *Field name already exists </span>
            ) : (
              ""
            )}
            <div className="mb-1">
              <label>Field Name</label>
              <input
                type="text"
                className="form-control b-b-primary"
                style={{ width: "150px" }}
                onChange={addHeader}
                value={header.header}
              />
            </div>
            <div>
              <Button color="primary" onClick={addColumn} className="mt-2 mb-4">
                Add To List
              </Button>
            </div>

            <div>
              <label>Available Categories</label>

              <div style={{ border: "1px solid black", width: "160px" }}>
                <Table
                  id="Custom_Fields_Table"
                  className="table-responsive"
                  width={300}
                  height={200}
                  cellPadding={0}
                  cellSpacing={0}
                >
                  <thead>
                    <tr>
                      <th colSpan={2} align="center" style={{ width: "160px" }}>
                        Name
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {column.map((HeaderName, HeaderKey) => (
                      <tr key={HeaderKey} onBlur={editColumnHeader}>
                        <td align="left" style={{ background: "#fff" }}>
                          <input
                            type="text"
                            className="form-control b-b-primary"
                            style={{ width: "155px" }}
                            onChange={addHeader}
                            value={HeaderName.headerText}
                            onFocus={() => editColumn(HeaderName.id)}
                            onClick={() => DeleteColumnId(HeaderName)}
                            readOnly
                          />{" "}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </ModalBody>

          <ModalFooter>
            <Button color="warning" onClick={toggleEditHeader}>
              {" "}
              Edit{" "}
            </Button>
            <Button
              color="warning"
              onClick={() => ToggleModalStates("DeleteConfirm")}
            >
              {" "}
              Delete{" "}
            </Button>
            <Button color="primary" onClick={ToggleEditModal}>
              {" "}
              Close{" "}
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={modal} centered={true} style={{ width: "350px" }}>
          <ModalHeader toggle={toggleEditHeader}>
            {"Edit Custom Fields"}
          </ModalHeader>

          <ModalBody>
            {column.map((value, key) => (
              <div className="row" key={key}>
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-2">
                      <label className="lbl_style">{key + 1}:</label>
                    </div>
                    <div className="col-md-8 mt-1" onBlur={editColumnHeader}>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Enter new header"
                        defaultValue={value.headerText}
                        onChange={addHeader}
                        onFocus={() => editColumn(value, key)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </ModalBody>

          <ModalFooter>
            <Button color="fourth" onClick={toggleEditHeader}>
              {" "}
              Update & Close{" "}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(MachineNew);
