import React, { useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const ShotSizeCalculator = () => {

    const [shotSizeData, setShotSizeData] = useState({
        screwDiameter: '',
        shotWeight: '',
        materialDensity: ''
    });

    const [shotSizeResult, setShotSizeResult] = useState(0);

    useEffect(() => {
        calculateResult();
    });

    const handleChange = (event) => {

        let { name, value } = event.target;

        // Convert to number if it's a float-like string (e.g., ".2" to "0.2")
        if (value.startsWith('.') && !isNaN(value)) {
            value = `0${value}`;
        }

        // Ensure the value is converted to a number if it's numeric
        const numericValue = parseFloat(value)

        setShotSizeData({ ...shotSizeData, [name]: numericValue })

    }

    const calculateResult = () => {

        let sd = shotSizeData.screwDiameter
        let sw = shotSizeData.shotWeight
        let md = shotSizeData.materialDensity

        if (sd !== 0 && sw !== 0 && md !== 0) {
            let res = Number((sw * 1000) / (0.785 * md * sd * sd)).toFixed(2);
            setShotSizeResult(res);
        }

        // Number((shotSizeData.shotWeight * 1000)/(shotSizeData.materialDensity * 0.785 * shotSizeData.screwDiameter * shotSizeData.screwDiameter)).toFixed(2)
    }

    return (
        <div>

            <div style={{ fontWeight: "bold", fontSize: '15px' }}>
                <span> English Units </span>
            </div>

            <hr></hr>

            <Form>
                <Form.Group className="mb-3" controlId="formBasicnumber">

                    <Row>

                        <Col sm={4}> <Form.Label>Screw Diameter (mm)</Form.Label> </Col>

                        <Col sm={4}>

                            <Form.Control name="screwDiameter" type="text" defaultValue={shotSizeData.screwDiameter} onChange={handleChange} min="0" onKeyPress={(event) => {
                                if (!/[0.0-9.0]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }} />

                        </Col>

                    </Row>

                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicnumber">

                    <Row>

                        <Col sm={4}> <Form.Label>Shot Weight (gms)</Form.Label> </Col>

                        <Col sm={4}>
                            <Form.Control name="shotWeight" type="text" defaultValue={shotSizeData.shotWeight} onChange={handleChange} min="0" onKeyPress={(event) => {
                                if (!/[0.0-9.0]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }} />
                        </Col>

                    </Row>

                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicnumber">
                    <Row>
                        <Col sm={4}>
                            <Form.Label>Material Density (gms/cm^3)</Form.Label> </Col>
                        <Col sm={4}>
                            <Form.Control name="materialDensity" type="text" defaultValue={shotSizeData.materialDensity} onChange={handleChange} min="0" onKeyPress={(event) => {
                                if (!/[0.0-9.0]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }} />
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicnumber">
                    <Row>
                        <Col sm={4}>
                            <Form.Label>Estimated Shot Size (mm)</Form.Label> </Col>
                        <Col sm={4}>
                            <Form.Control type="text" value={isNaN(shotSizeResult) ? '' : isFinite(shotSizeResult) ? shotSizeResult : ''}
                                onKeyPress={(event) => {
                                    if (!/[0.0-9.0]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }} readOnly />
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicnumber">

                    <Row>
                        <Col sm={4}>
                            <Form.Label>Estimated Shot Size (cm)</Form.Label> </Col>
                        <Col sm={4}>
                            <Form.Control type="text" value={isNaN(shotSizeResult / 10) ? '' : isFinite(shotSizeResult / 10) ? Number(shotSizeResult / 10).toFixed(2) : ''} onKeyPress={(event) => {
                                if (!/[0.0-9.0]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }} readOnly />
                        </Col>
                    </Row>

                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicnumber">

                    <Row>
                        <Col sm={4}> <Form.Label>Estimated Shot Size (inches)</Form.Label> </Col>

                        <Col sm={4}>
                            <Form.Control type="text" value={isNaN(shotSizeResult) ? '' : isFinite(shotSizeResult * 0.0393701) ? Number(shotSizeResult * 0.0393701).toFixed(2) : ''} onKeyPress={(event) => {
                                if (!/[0.0-9.0]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }} readOnly />
                        </Col>
                    </Row>

                </Form.Group>
            </Form>
        </div >
    )
}

export default ShotSizeCalculator;
